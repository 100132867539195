@import '../modules/colors';

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

body {
  .custom-button {
    border-radius: 0;
    box-shadow: 0 1px 2px 0 rgba($black, 0.5);
    font-weight: 500;
    line-height: 1em;
    text-transform: uppercase;

    i {
      font-size: 12px;

      &.left {
        margin-left: -4px;
        margin-right: 4px;
      }

      &.right {
        margin-left: 4px;
        margin-right: -4px;
      }

      &.flipped {
        filter: FlipH;
        transform: scaleX(-1);
      }
    }

    &.primary {
      background-color: $primary-blue;
      color: $white;
      font-size: 14px;
      height: 48px;

      i {
        font-size: 18px;
      }

      &:hover {
        background-color: $chathams-blue;
      }
    }

    &.secondary {
      background-color: $primary-grey-05;
      color: $primary-grey-80;

      i {
        font-size: 14px;
      }

      &:hover {
        background-color: $primary-grey-20;
      }
    }

    &.dangerous {
      background-color: $white;
      border: 1px solid $error-red;
      box-shadow: none;
      color: $error-red;

      &:hover {
        background-color: tint($error-red, 90%);
      }
    }

    &.muted {
      // no border
      box-shadow: none;
      color: $primary-grey-50;

      &:hover {
        background-color: $primary-grey-05;
        box-shadow: 0 1px 2px 0 rgba($black, 0.5);
      }
    }

    &.basic {
      // basically a link with/without an icon
      box-shadow: none;
      @each $color in $colorsList {
        &.#{nth($color, 1)} {
          color: nth($color, 2);
        }
      }

      &:hover {
        background-color: transparent;
      }
    }

    &.bordered {
      // has border
      border: 1px solid $primary-grey-80;
      box-shadow: none;
      color: $primary-grey-80;

      @each $color in $colorsList {
        &.#{nth($color, 1)} {
          border: 1px solid nth($color, 2);
          color: nth($color, 2);

          &:hover {
            background-color: tint(nth($color, 2), 80%);
          }
        }
      }
    }

    &.large {
      font-size: 14px;
      height: 48px;

      i {
        font-size: 18px;
      }
    }

    &.medium {
      font-size: 14px;
      height: 40px;

      i {
        font-size: 16px;
      }
    }

    &.small {
      font-size: 12px;
      height: 36px;

      i {
        font-size: 16px;
      }
    }

    &.x-small {
      font-size: 10px;
      height: 30px;
      min-height: 30px;

      i {
        font-size: 12px;
      }
    }

    &:hover {
      transition-duration: 0.3s;

      span {
        text-decoration: none;
      }
    }

    &:disabled {
      background-color: transparent;
      box-shadow: none;
      color: $primary-grey-40;
    }

    &.full-width {
      width: 100%;
      justify-content: center;
    }

    &.icon-button {
      color: $primary-grey-80;
      min-width: auto;
      padding: 8px;
      width: auto;

      i {
        font-size: 24px;
      }
    }
  }

  .custom-button-link:hover {
    text-decoration: none;
  }

  .plainWrapperButton {
    padding: 0;
    background: none;
    border: 0;
  }
}
