@import '../../../styles/modules/colors';

.select__input:not(.short) {
  min-width: 200px;
}

.select {
  display: flex;
  .select__dropdown {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-selection {
    &__rendered {
      display: flex;
      margin: 0;
      line-height: 36px;
      width: 100%;
      position: absolute;
      z-index: 1;
      ul > li {
        font-size: 14px;
      }
    }
    &__placeholder {
      left: 11px;
      font-size: 14px;
      color: #aba9a8;
    }
    &-selected-value {
      margin-left: 11px;
    }
  }
  .ant-select-search {
    &__field__wrap {
      margin-left: 11px;
    }
    &__placeholder {
      left: 11px;
      font-size: 12px;
      color: #aba9a8;
    }
  }
  &__dropdown {
    display: inline-flex;
    background: #fff;
    margin-bottom: 0;
    cursor: pointer;
  }
  &__icon {
    transform: translate(-30px);
    width: 0px;
    height: 36px;
    display: flex;
    align-items: center;
    .material-icons {
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      -o-transition: transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      &.open {
        transform: rotate(-180deg);
      }
    }
  }
}

.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item:hover {
  background-color: $primary-grey-20;
}
