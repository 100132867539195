@import 'styles/variables-p44.scss';

#footer {
  @include toRem(font-size, 12px);
  color: $white;
  font-weight: $font-weight-lighter;
  margin: auto auto 0;
  width: 100%;
  z-index: 1;

  a,
  a:hover {
    color: $white;
    font-weight: inherit;
    text-decoration: none;
  }

  .footer-logo-private {
    height: 68px;

    @include media-breakpoint-down(xs) {
      margin: 90px auto 40px;
    }
  }

  // Public Page Specific
  @include media-breakpoint-down(xs) {
    .footer-logo-public {
      margin: 90px auto 20px;
    }
  }

  .footer-column {
    list-style: none;
    margin: 40px 0;

    @include media-breakpoint-down(sm) {
      margin: 30px auto;

      &:last-of-type {
        margin-bottom: 60px;
      }
    }

    li {
      padding: 10px 0;
      text-align: left;

      @include media-breakpoint-down(sm) {
        margin: auto;
        max-width: 200px;
      }

      &:first-of-type {
        @include toRem(font-size, 14px);
        font-weight: $font-weight-bold;
      }
      &:nth-of-type(2) {
        @include toRem(font-size, 14px);
      }
    }

    // Public Page Specific
    &.public {
      margin-bottom: 40px;
      margin-top: 40px;

      li {
        margin: auto;
        max-width: 200px;
      }
    }
  }

  .legal-list {
    @include toRem(padding, 0 0 24px 0);
    list-style: none;
    text-align: center;

    li {
      @include toRem(font-size, 14px);
      border-right: 1px solid $white;
      display: inline;
      margin-right: 1rem;
      padding-right: 1rem;

      &:last-child {
        border: 0;
        margin: 0;
        padding: 0;
      }

      a {
        font-weight: inherit;
      }
    }
  }
}
