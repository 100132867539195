@import '../modules/mixins';

//Base styles
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto:100,300,400,500,700,900');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
  font-size: 62.5%;
}

body {
  background-color: var(--primary-grey-05);
  font-family: 'Roboto', sans-serif, 'Open Sans';
  font-size: 1.4rem;
  margin: 0;
}

// Color palette
// :root {
//   --primary-blue-30: #bdd0df;
//   --primary-blue: #00558B;
//   --off-blue: #5888b0;
//   --chathams-blue: #07456b;
//   --primary-grey: #2d2926;
//   --primary-grey-80: #575451;
//   --primary-grey-40: #aba9a8;
//   --primary-grey-20: #D5D4D4;
//   --primary-grey-05: #f3f3f3;
//   --mysterious-grey: #aba9a8;
//   --yellow: #ffcb05;
//   --orange: #f7941d;
//   --green: #00a651;
//   --new-fruit: #f04e37;
//   --side-padding: 87px;
//   --bluish: #1296b0;
//   --white: #ffffff;
//   --white-two: #d5d4d4;
//   --teal: #1296b0;
// }

.clearfix {
  clear: both;
  display: block;
}
