@import 'styles/variables-p44.scss';

:export {
  primaryColor: $primary-blue;
}

$hereMapHeight: 550px;

.port-congestion-map {
  background-color: $grey;
  height: 100%;
  width: 100%;

  .H_ib_body {
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 1px 8px 0 rgba($black, 0.5);
    color: $primary-grey;
    font-family: $font-family-base;
    font-size: 1.25rem;
    padding: 16px;
    width: 190px;

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $white;
      bottom: -5px;
      content: '';
      height: 0;
      position: absolute;
      right: 13px;
      width: 0;
    }

    .H_ib_close {
      svg {
        height: 20px;
        width: 20px;

        path {
          fill: $primary-grey;
        }
      }
    }
    .H_ib_content{
      .marker{
        .marker-title {
          font-size: 1.50rem;
          margin: 0 0 10px;
        }
        .marker-bullets, .aside-bullets {
          padding: 0;
          list-style: inside;
          margin: 0;
        }
    }
  }

  }
  .H_ib_tail {
    svg {
      display: none;
    }

    path {
      fill: $white;
    }
  }
}
