.multi-select {
  position: relative;

  .ant-select-selector {
    height: 38px;
  }

  .ant-select {
    &-multiple {
      .ant-select-selection-placeholder {
        opacity: 0.9;
      }
    }
  }

  .ant-select.multi-select__input {
    border: none;
  }

  .select__icon {
    position: absolute;
    height: 38px;
    width: 24px; // NOTE: material-icon has a native width of 24px, so this is matching that
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    &.open {
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    min-width: 200px;
  }
  .ant-select-selection-item {
    display: none !important;
  }
  .ant-select-selection {
    &--multiple {
      border: none;
      box-shadow: none;
    }
    &__rendered {
      display: flex;
      margin: 0;
      line-height: 36px;
      width: 100%;
      position: absolute;
      z-index: 1;
    }
    &__placeholder {
      margin-left: 11px;
      margin-right: 11px;
      font-size: 12px;
      color: #aba9a8;
    }
    &-selected-value {
      margin-left: 11px;
    }
  }
  .ant-select-search {
    &__field__wrap {
      margin-left: 11px;
    }
  }
  &__dropdown {
    position: relative;
    display: flex;
    background: #fff;
    cursor: pointer;

    &.no-search {
      .ant-select-selection__rendered {
        cursor: pointer;
      }
      .ant-select-search {
        opacity: 0;
      }
      .multi-select__input {
        .ant-select-selection__placeholder {
          display: block !important;
        }
      }
    }
  }
  &__input {
    min-width: 200px;
    border: 1px solid #d9d9d9;
  }
  &__icon {
    transform: translate(-30px);
    width: 0px;
    height: 36px;
    display: flex;
    align-items: center;
    .material-icons {
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      -o-transition: transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      &.open {
        transform: rotate(-180deg);
      }
    }
  }
}
