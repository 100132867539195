@import "styles/variables-p44.scss";

.autosuggest {
  &__container {
    min-width: 320px;
    width: 100%;
  }

  &__suggestions-container {
    max-height: 290px;
    overflow: auto;
  }

  &__input {
    border: 1px solid $primary-grey-20;
    color: #000;
    width: 100%;
    font-size: 14px;
    margin-bottom: 16px;
    padding: 7px 12px 9px;

    &::placeholder {
      color: #aba9a8;
    }

    &:focus {
      border: 1px solid #186C9C;
      outline: 2px solid rgba(0, 85, 139, 0.2);
    }
  }

  &__suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__suggestion {
    font-size: 16px;
    margin-top: 8px;

    &--first {
      margin-top: 0;
    }
  }

  &__item {
    border: 1px solid transparent;
    color: $primary-grey;
    padding: 7px 8px;
    border-radius: 4px;

    &:hover {
      border: 1px solid $primary-grey-05;
      background-color: $primary-grey-05;
      cursor: pointer;
    }

    &.is-selected {
      border: 1px solid #186c99;
      cursor: pointer;
    }
  }
}
