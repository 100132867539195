@import 'styles/bootstrap/_bootstrap.scss';
.analytics {
  .section-content {
    min-height: 600px;
    position: relative;
    padding-left: 100px;
  }
  .oi-wrapper-container{
    width: 100%;
    padding: 34px 48px;
    .oi-wrapper-container__alert{
      color: $primary-grey;
      font-weight: 500;
      font-size: 1em;
    }
    .ant-alert-info{
      background-color: #CCE3FB;
      border: 1px solid #0072ED;
      margin-bottom: 16px;
      .ant-alert-icon {
        color: #0072ED;
      }
    }
  }
}
