@import 'styles/variables-p44.scss';

.route-group {
  .route-update {
    &__label {
      color: $primary-grey-50;
      padding-top: 10px;
    }

    &__expand {
      color: $teal;
      cursor: pointer;
      font-weight: $font-weight-semibold;
      padding: 10px 20px 1px;
      text-transform: uppercase;
    }

    &__date {
      padding-top: 10px;
    }

    &__line {
      .vertical-line {
        &:after {
          border: 3px solid $primary-grey-05;
          height: 14px;
          top: 20px;
          width: 14px;
        }

        &:before {
          @include toRem(font-size, 14px);
          color: $primary-grey-35;
          content: '\002B';
          display: block;
          font-weight: $font-weight-bold;
          left: -20px;
          position: absolute;
          top: 9px;
          z-index: 1;
        }
      }
    }
  }

  &.expanded {
    .route-update {
      &__line {
        .vertical-line {
          &:after {
            border: none;
            height: 8px;
            width: 8px;
          }

          &:before {
            display: none;
          }
        }
      }

      &__label {
        color: $primary-grey-80;
      }

      &__vertical-divider {
        background: $primary-grey-20;
        margin: 0 10px 0 34px;
        width: 1px;
      }

      &__expand {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
