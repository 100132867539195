@import 'styles/variables-p44.scss';
@import 'styles/modules/_p44Card.scss';

.card {
  @extend .p44Card;
  color: $primary-grey;
  display: block;
  margin-top: 20px;

  p {
    margin: 0.2rem 0;
  }
}

.cardBody {
  padding: 15px 25px 5px 25px;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}

.shipmentInfoContainer {
  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }
}

.stopInfoContainer {
  display: flex;
  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }
}

.equipmentIdSelect {
  margin-bottom: 5px;
  width: 100%;
}

.divider {
  content: ' ';
  width: calc(100% - 25px);
  border-bottom: 1px solid $primary-grey-20;
  margin: 0 10px 20px 10px;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.saveButton {
  margin-top: 5px;
  max-width: 80px;
  padding: 10px;
  align-self: flex-end;
  color: $chathams-blue !important;
  font-weight: $font-weight-semibold !important;
  height: 40px !important;
}

.identifiers {
  margin-bottom: 10px;
}

.arrow {
  transform: rotate(180deg);
  margin: 0 15px;
}
