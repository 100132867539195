.ant-calendar {
  &-input {
    font-size: 12px;
  }
  &-date {
    &:hover {
      border-radius: 50%;
    }
  }
  &-today {
    &:not(.ant-calendar-disabled-cell) {
      .ant-calendar-date {
        border-radius: 50%;
      }
    }
  }
  &-selected-day {
    &:not(.ant-calendar-disabled-cell) {
      .ant-calendar-date {
        background: #00558b;
        font-weight: bold;
        color: #FFF;
        border-radius: 50%;
        border-color: #00558b;
        &:hover {
          border-radius: 50%;
        }
      }
    }
  }
  .ant-calendar-footer-btn {
    .ant-calendar-today-btn {
      color: #1296b0;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.ant-calendar-picker {
  .ant-calendar-picker-input {
    font-size: 12px;
  }
}
