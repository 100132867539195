@import 'styles/variables-p44.scss';

.notification-dialog {
  [role='document'] {
    border-radius: 0;
    min-width: 600px;

    #responsive-dialog-title {
      border-bottom: 1px solid $primary-grey-20;
      padding: 15px;

      h2 {
        @include toRem(font-size, 24px);
        position: relative;
      }
      .close-dialog-icon {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 4px;
      }
    }
    .notification-dialog-content-container {
      overflow-y: unset;

      .message-content {
        @include toRem(font-size, 14px);
        margin: 20px 0 16px;
      }
    }

    .text-message-container {
      .text-message-content {
        .message-box {
          @include toRem(font-size, 16px);
          background-color: $primary-grey-30;
          margin-right: 30px;
          padding: 14px 16px;
          position: relative;

          &:after {
            border-left: 35px solid transparent;
            border-right: 35px solid transparent;
            border-top: 35px solid $primary-grey-30;
            bottom: -5px;
            content: '';
            display: block;
            position: absolute;
            right: -30px;
            transform: rotate(45deg);
          }
        }
      }
    }

    .notification-dialog-actions {
      background-color: $primary-grey-05;
      margin: 0;
      padding: 15px;
    }
  }
}

.notification-dialog[role='dialog'] {
  z-index: 10000;
}
