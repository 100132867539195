.add-edit-dialog {
  [role='document'] {
    border-radius: 0;
    display: flex;
    margin: auto;
    max-width: 800px;
    min-width: 800px;

    > div {
      flex: 1;
    }
  }
}
