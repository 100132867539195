.shipment-details-ocean {
  .share-notifications-wrapper {
    display: flex !important;
    justify-content: flex-end;
  }

  .shipment-stops {
    &__stops {
      justify-content: normal;

      .shipment-stop {
        position: relative;
      }
    }
  }
}
