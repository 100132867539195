@import '../../../styles/modules/colors';

.chips {
  .chips__row {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 12px;
  }
  .column {
    flex-direction: column;
    display: flex;
  }

  &__show-all {
    padding: 10px 7px;
    display: inline-block;
    cursor: pointer;
    .text_action {
      display: flex;
      font-size: 10px;
      align-items: center;
    }
  }
}

.chip {
  display: flex;
  background-color: $primary-grey-80;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
  margin: 0 3px 5px 0;
  align-self: flex-start;
}

.chip__label {
  font-size: 14px;
  line-height: 18px;
  color: $white;
  padding: 5px 12px;
  font-weight: 400;
}

.chip__delete {
  color: $white;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  padding: 0 5px;
}
