@import 'styles/variables-p44.scss';

.selected-status-chips-container {
  margin: 1em 0 !important;

  .selected-status-filter-chip {
    margin: 0 0 .5em !important;
    position: relative;

    &:after {
      @include toRem(font-size, 16px);
      color: $white;
      content: 'clear';
      font-family: 'Material Icons';
      margin-left: -3px;
      margin-right: 4px;
    }
  }
}
