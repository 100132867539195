@import 'styles/variables-p44.scss';

.scheduledTruckChangeOptionsContainer {
    margin-bottom: 15px;
}

.date-picker-custom {
    .form-group.datepicker-group.col {
        padding: 0px !important;
        div {
            margin: 0px !important;
        }
    }
}