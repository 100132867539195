@import 'styles/variables-p44.scss';

.AddEditNotificationComponent {
  .sub-header-label {
    @include toRem(font-size, 16px);
    color: $primary-grey-80;
    font-weight: $font-weight-light;
  }

  .add-edit-notification {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba($black, 0.5);
    height: 100%;
    padding: 20px;
    width: 100%;

    display: flex;
    justify-content: space-around;

    .delete-button > .delete-icon {
      margin-left: 5px;
    }
  }

  .time-selector {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75px;

    input[type='number'] {
      padding-left: 15px;
    }
  }

  .footer-container {
    margin-bottom: 68px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-select-selection-item {
    width: 100%;
  }
}
.ant-select-dropdown.country-code-dropdown {
  width: 400px !important;
}

.language-select,
.push-notification-select {
  .ant-select-arrow {
    right: 25px;
  }
}
