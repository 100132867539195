@import 'styles/variables-p44.scss';

.form-group {
  position: relative;
  @include toRem(margin-bottom, 17px);

  label {
    @include toRem(margin-bottom, 4px);
    @include toRem(font-size, 10px);
    color: $teal;
    display: block;
    font-weight: $font-weight-semibold;
  }

  .form-control {
    @include toRem(padding, 0 8px);
    @include toRem(height, 34px);
    @include toRem(font-size, 12px);
    background-color: $white;
    border-radius: 0;
    border: 1px solid $primary-grey-20;
    box-shadow: none;
    color: $primary-grey-80;
    font-weight: $font-weight-semibold;
    outline: none;
    transition: none;
    width: 100%;

    &.is-invalid {
      border: 1px solid $new-fruit;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .invalid-feedback {
    @include toRem(top, 52px);
    @include toRem(font-size, 14px);
    color: $new-fruit;
    position: absolute;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }
}

input {
  // used for input placeholders
  &::-webkit-input-placeholder {
    // Chrome, Firefox, Opera, Safari 10.1+
    color: $primary-grey-40 !important;
    opacity: 1; // Firefox
  }

  &:-ms-input-placeholder {
    // Internet Explorer 10-11
    color: $primary-grey-40 !important;
  }

  &:-ms-input-placeholder {
    // Internet Explorer 10-11
    color: $primary-grey-40 !important;
  }

  &::-ms-input-placeholder {
    // Microsoft Edge
    color: $primary-grey-40 !important;
  }

  &:focus {
    // overrides bootstrap focus
    border: none;
    outline: none;
  }
}

.input-container.maintain-width {
  display: block;
}

.input-container.select-dropdown .select-placeholder {
  color: $primary-grey-40;
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  left: 8px;
  position: absolute;
  top: 26px;
}

button {
  &:focus {
    outline: none;
  }
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-form-item-has-error .ant-form-item-explain {
  color: $error-red;
}
