@import '../../../../styles/modules/colors';

.chip__matched-search {
  background: $highlight-yellow;
  box-shadow: none;
  min-height: 30px;
  align-items: center;
  padding: 5px 12px;

  span {
    font-size: 16px;
    color: $primary-grey;
    line-height: 20px;
    padding: 0px;
  }
}
