@import "styles/variables-p44.scss";

.shipment-counts-widget {
  button {
    position: absolute;

    &.edit-quickview {
      @include toRem(font-size, 12px);
      background-color: transparent;
      color: $teal;
      min-width: 60px;
      right: 0;
      z-index: 1;

      i {
        @include toRem(font-size, 15px);
        padding-left: 3px;
      }
    }

    &.delete-quickview {
      height: auto;
      left: 0;
      padding: 6px;
      width: auto;

      i {
        @include toRem(font-size, 20px);
      }
    }
  }

  .widget-container {
    background-color: $secondary-background-color;
    box-shadow: 0 1px 2px 0 rgba($black, 0.5);
    flex-wrap: nowrap;
    height: 100%;
    padding: 20px;

    &:hover {
      cursor: pointer;
    }

    .widget-icon-column {
      margin-top: 20px;
      text-align: right;

      .widget-icon {
        height: 90px;
        width: 90px;
      }
    }

    .widget-count {
      @include toRem(font-size, 49px);
      color: $primary-grey-80;
      font-weight: $font-weight-light;
      line-height: 0.4;
      padding-right: 4px;
    }

    .widget-search-link {
      @include toRem(font-size, 14px);
      color: $primary-blue;
      cursor: pointer;
      font-weight: $font-weight-bold;
      min-height: 39px;
      padding: 10px 0;
      text-align: right;
      text-transform: uppercase;
    }

    .widget-info-column {
      @include toRem(font-size, 14px);
    }

    .widget-heading {
      @include toRem(font-size, 14px);
      font-weight: $font-weight-semibold;
      line-height: 2.57;
    }

    .widget-sub-heading {
      @include toRem(font-size, 21px);
      color: $primary-grey;
      font-weight: $font-weight-normal;
      hyphens: auto;
      line-height: 1.08;
      word-break: break-word;
    }

    &.span-horizontal {
      flex-basis: 0;
      flex-direction: column;
      flex-grow: 1;
      height: 80px;
      justify-content: center;
      min-height: 80px;

      .widget-text {
        padding-right: 15px;
      }

      .widget-heading {
        @include toRem(font-size, 18px);
        color: $primary-grey-80;
        font-weight: $font-weight-light;
        justify-content: center;
      }
    }
  }
}
