@import 'styles/variables-p44.scss';

.asideContainer {
  padding: 32px;
  max-width: 300px;
  width: 100%;
  overflow-y: auto;
}

.asideTitle {
  font-size: 1.8rem;
}

.asideBullets {
  padding: 0;
  list-style: inside;
  margin: 0;
}
