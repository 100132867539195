@import 'styles/variables-p44.scss';

.statusHeaderContainer {
  @include toRem(margin-top, 16px);
  @include toRem(margin-bottom, 38px);
}

.statusLineContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include toRem(margin-bottom, 100px);
}

.statusLine {
  display: flex;
  align-items: center;
}

.tag {
  margin-right: 10px;
}

.currentStatusHeader {
  @include toRem(font-size, 21px);
  font-weight: $font-weight-semibold;
  color: $primary-grey;
}

.eta {
  @include toRem(font-size, 16px);
  color: $primary-grey;
}

.updateTimestampsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  @include toRem(padding-top, 5px);
}

.lastUpdatedText {
  @include toRem(font-size, 14px);
  font-style: italic;
  color: $primary-grey-80;
}
