@import 'styles/variables-p44.scss';

.settingsNav {
  align-self: flex-start;
  border: 1px solid $light-border-color;
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}

.navItem {
  @include toRem(font-size, 16px);
  align-items: center;
  border-top: 1px solid $light-border-color;
  color: $primary-grey;
  cursor: pointer;
  display: flex;
  font-weight: $font-weight-semibold;
  height: 47px;
  padding-left: 8px;
  padding-right: 36px;
  white-space: nowrap;

  &:hover,
  &:visited {
    color: $primary-grey;
    text-decoration: none;
  }

  &:first-child {
    border-top: none;
  }

  svg {
    margin-right: 8px;
  }
}

.selectedNavItem {
  background-color: $active-background-color;
}
