@import 'styles/variables-p44.scss';

.map-side-panel-item {
  align-items: center;
  border-bottom: 1px solid $white-two;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 20px;

  &--active {
    @extend .map-side-panel-item;
    background-color: $primary-grey-05;
  }

  &:hover {
    background-color: $primary-grey-05;
    cursor: pointer;
  }

  .map-side-panel-item__icon {
    margin-right: 4px;
  }

  .map-side-panel-item__content {
    color: $primary-grey-80;
    width: 100%;

    p {
      margin-bottom: 0;
    }

    .map-side-panel-item__time {
      float: right;
      font-weight: $font-weight-bold;
    }
  }
}
