@import 'styles/variables-p44.scss';
.subLineItemsContainer {
  margin: 0;
  padding: 0;
  .lineItemDetail {
    margin-bottom: 10px;
    font-size: 14px;
    .label {
      color: $primary-grey-80;
    }
    .value {
      color: $primary-grey;
    }
  }
  .sublineItemsTable {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 10px;
  }
}
