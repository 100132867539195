@import 'styles/variables-p44.scss';

// Share Badge Variables
$share-text-color: darken($teal, 7%);
$share-icon-size: 2rem;
$share-icon-color: $share-text-color;

// Share Badge CSS Classes
.shareBadge {
  align-items: center;
  color: $share-text-color;
  display: inline-flex;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  vertical-align: middle;
  @include toRem(font-size, 14px);

  &::before {
    color: $share-icon-color;
    content: '\e7ef';
    font-family: 'Material Icons';
    font-size: $share-icon-size;
    margin-right: $share-icon-size / 3;
  }

  &:empty,
  &:empty::before {
    display: none;
  }
}
