@import 'styles/variables-p44.scss';

.title {
  @include toRem(font-size, 21px);
  color: $primary-text-color;
  margin-bottom: 22px;
  margin-top: 40px;
}

.buttonAndDescriptionWrapper {
  display: flex;
}

.description {
  margin-left: 32px;
}
