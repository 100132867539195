@import 'styles/variables-p44.scss';

.locations-list {
  .header-wrapper {
    align-items: center;
    display: flex;
  }

  .locations-results {
    .results-summary {
      @include toRem(font-size, 24px);
      color: $primary-grey;
      font-weight: $font-weight-light;
    }

    .results-sort {
      margin-left: auto;
    }

    .location-item {
      flex-direction: column;
      margin-top: 12px;

      .contact-details {
        color: $primary-grey-80;

        a {
          margin-left: 5px;
        }
      }

      .view-details {
        margin-left: auto;
      }

      .location-row {
        padding: 15px 15px 0;

        &:first-of-type {
          border-bottom: 1px solid $white-two;
          padding-bottom: 10px;
          padding-top: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  // (min-width: 576px)
  .locations-list .search-box {
    float: right;
    width: 90%;
  }
}

@include media-breakpoint-down(xs) {
  // (max-width: 575px)
  .locations-list .location-row {
    > div {
      width: 50%;

      p {
        margin: 0;
      }
    }

    .view-details {
      margin-right: auto;
      text-align: center;
    }
  }
}
