@import 'styles/variables-p44.scss';

.NotificationsComponent {
    .sub-header-label {
      @include toRem(font-size, 16px);
      color: $primary-grey-80;
      font-weight: $font-weight-light;
    }

    .new-notification-button {
      width: 160px;
    }
}
