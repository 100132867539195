@import 'styles/variables-p44.scss';
@import 'styles/modules/_colors.scss';
.LocationMap {
  background: $primary-grey-32;
  float: right;
  height: 318px;
  margin: 0;
  width: 75%;
  position: relative;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.editGeofenceButton {
  position: absolute !important;
  top: 10px;
  left: 1%;

  @include media-breakpoint-down(md) {
    left: 1%;
  }
}

.geofenceLegendBox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: $polygonShade;
}

.geofenceLegend {
  position: absolute;
  top: 10px;
  font-size: 1.4rem;
  color: $white;
}
