@import 'styles/variables-p44.scss';
@import 'styles/modules/_p44Card.scss';

.ResultDetail {
  .ShipmentStatus {
    max-width: 100%;
    width: auto;

    > p {
      @include toRem(font-size, 12px);
      margin: 0;

      &:nth-of-type(1) {
        @include toRem(font-size, 16px);
        display: inline-block;
        margin-top: 5px;
      }

      &:nth-of-type(2) {
        @include toRem(font-size, 16px);
        display: inline-block;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }
    }
  }

  .share-badge {
    background-color: rgba($primary-grey-05, 0.6);
    padding: 0.5rem 15px;
    width: 100%;
  }

  .tracking-info {
    padding-bottom: 1.5rem;
  }

  footer {
    padding-bottom: 0 !important;
  }

  .status-distance {
    padding-bottom: 5px;
    text-align: center;

    p {
      @include toRem(font-size, 14px);
      margin-bottom: 0;
    }
  }

  .nudge-driver span {
    color: $primary-blue;
    cursor: pointer;
    width: auto;
  }

  .nudge-link {
    padding: 0;
    outline: none;
    border: 0;
    margin: 0;
    background: none;
  }

  .eta-message {
    margin-left: 5px;
  }

  .etaOutdatedText {
    color: $new-error-red;
    margin-top: -2px;
  }

  .etaText {
    color: $green;
    margin-top: -11px;
  }

  @media only screen and (max-width: 559px) {
    footer {
      p {
        @include toRem(font-size, 12px);
        color: $primary-grey-80;
        font-weight: $font-weight-normal;
        letter-spacing: normal;
        line-height: 1.33;
        margin: 3px 0;
        text-align: left;
      }
    }

    .eta-message {
      @include toRem(font-size, 14px);
      color: $primary-grey-80;
    }

    header h2 {
      color: $primary-grey;
      font-weight: $font-weight-light;
      text-align: center;
      width: 100%;
    }

    .last-update-message {
      @include toRem(font-size, 12px);
      color: $primary-grey-80;
      font-style: italic;
    }
  }

  @media only screen and (min-width: 559px) {
    footer {
      border-top: 1px solid $white-two;

      p {
        @include toRem(font-size, 14px);
        color: $primary-grey-80;
        font-weight: $font-weight-normal;
        letter-spacing: normal;
        line-height: 1.33;
        margin: 3px 0;
        text-align: left;
      }

      span {
        @include toRem(font-size, 14px);
      }
    }

    header.upper-right-section {
      text-align: right;

      span {
        @include toRem(font-size, 12px);
        color: $white;
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        margin: 0.75em 0 0;

        &.eta-message {
          @include toRem(font-size, 14px);
          color: $primary-grey-80;
          margin: 0 0 0 1em;
        }
      }
    }

    .down-right-section {
      text-align: right;
    }

    section {
      margin-top: 10px;
    }
  }
}
