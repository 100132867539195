@import 'styles/variables-p44.scss';

.shipment-details {
  .shipment-status {
    &__last-update {
      color: $primary-grey-80;
      font-style: italic;
      text-align: right;
    }

    &__forecast-text {
      @include toRem(font-size, 14px);
    }

    &__stops {
      margin: 3.5em 1em 0.5em;
    }

    &__share-badge {
      background-color: rgba($primary-grey-05, 0.6);
      padding: 0.5rem 15px;
      width: 100%;
    }

    &__tracking-methods {
      padding-bottom: 1.5rem;
    }
    
    &__tracking-method {
      @include toRem(font-size, 14px);
    }
    footer {
      padding-bottom: 0;
    }

    .card-divider {
      background-color: $primary-grey-20;
      height: 1px;
      margin: 11px -15px;
    }

    .status-color-tag {
      margin: 0 5px 0 0;
    }
  }
}

.active-holds-label {
  @include toRem(font-size, 12px);
  color: $primary-grey-80;
  font-weight: $font-weight-bold;
  padding-top: 1px;
  text-transform: uppercase;
}

.active-holds-count-bubble {
  @include toRem(font-size, 10px);
  background-color: $orange;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  margin-left: 7px;
  border-radius: 10rem;
  display: inline-block;
  font-weight: $font-weight-bold;
  line-height: 1;
  padding: 0.25em 6px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.status-demurrage-risk {
  @include toRem(font-size, 12px);
  background-color: $orange;
  color: $white;
  font-weight: $font-weight-bold;
  padding-left: 3px;
  padding-right: 3px;
  text-transform: uppercase;
}

.shipment-holds {
  &__type {
    @include toRem(font-size, 12px);
    margin-right: 20px;
    text-transform: uppercase;
  }

  &__detail {
    @include toRem(font-size, 12px);
    color: $primary-grey;
    font-weight: $font-weight-bold;
  }

  &__timestamp {
    @include toRem(font-size, 12px);
    color: $primary-grey-40;
    margin-right: 3px;
  }
}

@include media-breakpoint-down(xs) {
  .shipment-details {
    .shipment-status {
      text-align: center;

      &__tracking {
        text-align: center;
      }
    }
  }
}
