@import 'styles/variables-p44.scss';

.SearchBar {
  margin-right: 16px;
  max-width: 345px;

  @media only screen and (min-width: 768px) and (max-width: 870px) {
    max-width: 270px;
  }

  @media only screen and (max-width: 767px) {
    margin-right: 0;
    max-width: 100%;
  }

  input {
    @include toRem(font-size, 14px);
    background-color: $white !important;

    box-shadow: none !important;
    height: 50px;
    outline: none;
    padding: 6px 12px;
  }

  button {
    line-height: inherit;

    @media only screen and (max-width: 769px) {
      box-shadow: none !important;
    }
  }

  .validationMessage {
    @include toRem(font-size, 14px);
    display: flex;
    justify-content: center;
    color: $new-error-red;
  }
}
