@import 'styles/variables-p44.scss';

.shipment-route {
  .route-detail {
    &__icon {
      align-items: center;
      display: flex;
      flex-direction: column;

      img {
        width: 33px;
      }

      .label {
        text-transform: uppercase;
      }
    }

    &__stop-info {
      @include toRem(font-size, 12px);
      color: $primary-grey-80;
      padding-left: 15px;

      .appointment-info {
        &__company {
          @include toRem(font-size, 16px);
          color: $primary-grey;
          font-weight: $font-weight-bold;
        }

        &__divider {
          @include toRem(font-size, 14px);
          color: $primary-grey-80;
          padding: 0 9px;
        }

        &__location {
          @include toRem(font-size, 14px);
          color: $primary-grey-80;
        }
      }
    }

    .appointment-window {
      padding: 12px 0;

      &__label {
        text-transform: uppercase;
      }
    }

    .stop-contact-info {
      color: $primary-grey-80;
      @include toRem(font-size, 12px);

      &__divider {
        padding: 0 5px;
      }

      &__phone {
        color: $teal;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .shipment-route {
    &__summary {
      .route-detail {
        &__icon {
          .label {
            display: none;
          }
        }
      }

      .appointment-window,
      .stop-contact-info {
        display: none;
      }

      .appointment-info {
        display: flex;
        flex-direction: column;

        &__divider {
          display: none;
        }
      }
    }

    &__stop-count {
      @include toRem(font-size, 12px);
      font-weight: $font-weight-bold;
      padding-bottom: 12px;
      text-align: left;

      .route-connect-arrow {
        display: none;
      }
    }
  }
}
