@import 'styles/variables-p44.scss';

.shipment-summary {
  p {
    @include toRem(font-size, 12px);
    color: $primary-grey-80;
  }

  .tracking-heading {
    @include toRem(font-size, 16px);
    color: $primary-grey;
    font-weight: $font-weight-bold;
    margin: 0 0 2px;
    text-align: center;
  }

  .tracking-carrier {
    @include toRem(font-size, 14px);
    color: $primary-grey-40;
    font-weight: $font-weight-bold;
    margin: 0 0 20px;
    text-align: center;
  }

  .tracking-details {
    .tracking-details-row {
      display: flex;
      margin-bottom: 25px;

      .tracking-details-label {
        @include toRem(font-size, 12px);
        color: $primary-grey-40;
        flex-basis: 25%;
        font-weight: $font-weight-bold;
        padding-right: 13px;
        text-align: right;
      }

      .tracking-details-description {
        @include toRem(font-size, 12px);
        flex-basis: 75%;

        .temperature-lower-bound{
          color:$primary-blue !important;
          font-weight: $font-weight-light !important;
        }

        .temperature-target-temp {
          color: $primary-grey !important;
          font-weight: $font-weight-semibold !important;
        }

        .temperature-upper-bound {
          color: $new-fruit !important;
          font-weight: $font-weight-light !important;
        }
      }

      .tracking-subheading {
        @include toRem(font-size, 16px);
        color: $primary-grey;
        font-weight: $font-weight-bold;
        line-height: 1;
        margin: 0 0 10px;
      }

      .tracking-subtitle {
        @include toRem(font-size, 10px);
        color: $primary-grey-80;
        line-height: 1.5;
        margin: 0;
        text-transform: uppercase;
      }

      .tracking-details {
        @include toRem(font-size, 12px);
        color: $primary-grey-80;
        line-height: 1.5;
        margin: 0;
      }
    }
  }
}
