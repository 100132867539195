@import 'modules/mixins';
@import 'modules/colors';

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base: 'Roboto', sans-serif !default;
$font-size-base: 1.4rem !default;
// Roboto font weights :  100,300,400,500,700,900
$font-weight-lightest: 100 !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-semibolder: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 900 !default;

// Box shadow for cards
$boxShadow: 0 1px 2px 0 rgba($black, 0.5);
