@import 'styles/variables-p44.scss';

.sort-results {
  color: $primary-grey-80;
  text-align: right;

  h6 {
    @include toRem(font-size, 11px);
    font-weight: $font-weight-semibold;
    text-transform: capitalize;
  }
}
