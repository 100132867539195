@import 'styles/variables-p44.scss';

.title {
  @include toRem(font-size, 21px);
  margin-bottom: 24px;
}

.uploadAndHelpWrapper {
  display: flex;

  @media (max-width: 1075px) {
    flex-wrap: wrap;
  }
}

.dropzone {
  align-items: center;
  border: 2px dashed $dark-border-color;
  display: flex;
  flex-shrink: 0;
  height: 115px;
  justify-content: space-around;
  outline: none;
  overflow: hidden;
  transition: .2s background-color;
  width: 338px;
}

.dropzoneWrapper { 
  display: flex;
  flex-direction: column;
}

.errorMessage {
  @include toRem(font-size, 12px);
  color: $error-color;
  margin-left: auto;
  margin-right: auto;
}

.previewWrapper {
  display: flex;
  flex-direction: column;
}

.previewImage {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.remove {
  @include toRem(font-size, 12px);
  color: $link-color;
  cursor: pointer;
  font-weight: $font-weight-bold;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  text-align: center;
  text-transform: uppercase;
}

.toggle-carrier-onboarding-logo-wrapper__div {
  margin-bottom: 20px;
}

.toggle-carrier-onboarding-logo__label {
  padding: 0 10px;
}
