@import 'styles/variables-p44.scss';

$card-x-margin: 1.5rem;
$card-y-margin: 1.5rem;

$card-x-padding: 1.5rem;
$card-y-padding: 1.5rem;

$card-border-color: $primary-grey-20;

.p44Card {
  background: $white;
  box-shadow: $boxShadow;
  margin-bottom: 1rem;

  header {
    border-bottom: 1px solid $card-border-color;
  }

  header,
  main,
  footer {
    padding: $card-x-padding $card-y-padding;

    .row {
      margin-left: -$card-x-padding;
      margin-right: -$card-x-padding;
    }
  }

  footer {
    border-top: 1px solid $card-border-color;

    &.footerBg {
      background-color: $primary-grey-05;
      border: none;
    }
  }
}
