@import 'styles/modules/_colors.scss';

.filters-modes-dropdown {
  padding: 32px;

  .ant-select-item-option {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 5px 10px;
    color: $primary-grey;
    font-weight: 400;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid $primary-grey-05;
      background-color: $primary-grey-05;
    }

    &-selected {
      border: 1px solid $p44-blue;
      background-color: transparent;
      &:hover {
        border-color: $p44-blue;
      }
    }
  }
}
