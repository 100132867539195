@import 'styles/variables-p44.scss';

.FilterComponent {
  background-color: $primary-background-color;
  border: 1px solid $light-border-color;
  padding-bottom: 15px;
  padding-top: 15px;

  @include media-breakpoint-down(xs) {
    animation-duration: 1s;
    animation-name: slideInLeft;
    animation-fill-mode: both;
  }

  label {
    @include toRem(font-size, 13px);
    color: $primary-grey;
    display: block;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    margin-bottom: 0.5em;
    margin-top: 0;

    // Keep ant default label styles
    &.ant-checkbox-wrapper {
      @include toRem(font-size, 14px);
      color: #575451;
      display: inline-block;
      font-weight: $font-weight-normal;
      line-height: unset;
      margin: 0;
    }
  }

  // Make text input fluid
  * .select__dropdown,
  * .multi-select__input {
    margin-bottom: 0;
    min-width: 100%;
    width: 100%;

    .select__input {
      min-width: 100%;
    }
  }

  * .multi-select .select__icon {
    position: inherit;
    right: 30px;
    top: inherit;
    -webkit-transform: translateY(-50%);
    transform: inherit;
    width: 36px;
    z-index: 2;
  }

  .refine-header {
    flex-wrap: wrap;
    margin: 0 0 20px;

    .refine-text {
      @include toRem(font-size, 24px);
    }

    .button-wrapper {
      display: flex;
      margin-left: auto;
    }
  }

  .filter-button-container {
    button {
      min-width: 54px;

      &:first-of-type {
        margin-left: auto;
        margin-right: 12px;
      }
    }
  }

  .filter-close-button {
    cursor: pointer;
    height: 40px;
    margin-left: 5px !important;
    margin-right: 0 !important;
    max-width: 40px;
    min-width: 40px !important;
    width: 40px !important;
  }

  h4 {
    @include toRem(font-size, 13px);
    font-weight: $font-weight-bold;
  }

  > section > section {
    font-weight: $font-weight-light;
    margin-bottom: 12px;
  }

  section.pickup-datepicker-row,
  section.delivery-datepicker-row {
    margin-bottom: 12px;
  }

  .check-list {
    @include toRem(font-size, 15px);
    width: 100%;

    * .ant-checkbox-wrapper + span,
    * .ant-checkbox + span {
      padding-right: 0;
    }
  }

  .view-results {
    color: $white;
    cursor: pointer;
    margin: 2em 0 0;
  }

  .status-dropdown-row {
    #statusFilterDropdownItems {
      background-color: $white;
      width: 100% !important;

      div:nth-of-type(2) {
        padding-left: 0.5em !important;

        &:before {
          color: $primary-grey-40;
          content: 'Filter by Status   ';
        }
      }

      button {
        width: 16%;
      }
    }
  }

  .selected-status-filter-chip {
    height: 24px !important;
    margin: 0 0 0.5em !important;
    max-width: 100%;

    span {
      @include toRem(font-size, 11px);
      line-height: 24px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      height: 24px !important;
      margin: 0 !important;
      width: 24px !important;
    }
  }

  .range-datepicker-button {
    border: none !important;
    height: 36px !important;
    left: 2px !important;
    position: relative !important;
    width: 42px !important;
  }

  .footer-filter-controls-container {
    display: flex;
    text-align: right;
  }
}

// generated by material ui, needs to be scoped outside of filter component
.status-filter-dropdown-menu-item {
  svg {
    left: -18px !important;
    transform: scale(0.8) !important;
  }

  div div {
    padding: 0 0 0 5px !important;
  }
}

@include media-breakpoint-down(lg) {
  .FilterComponent .refine-header .button-wrapper {
    margin-left: 0;
    margin-top: 10px;
  }
}

@include media-breakpoint-down(md) {
  .FilterComponent {
    .refine-header {
      .button-wrapper {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .filter-close-button {
      background: transparent;
      border: none;
    }
  }
}
