@import 'styles/variables-p44.scss';

.ShipmentListHeaderComponent {
  flex: 1;
}

@include media-breakpoint-down(md) {
  .ShipmentListHeaderComponent {
    background-color: $white;
    flex-direction: column;

    div.shipment-list-header-button-container {
      position: relative;
    }

    h1 {
      text-align: center;
    }

    .SearchBar {
      flex-grow: 1;
      padding-left: 0;
      padding-right: 0;

      button {
        background: $white;
        border: none;
        box-shadow: none;

        &:hover,
        &:focus {
          background: $white;
        }

        .anticon-search {
          color: $primary-grey-80;
        }
      }

      input {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .ShipmentListHeaderComponent {
    background-color: $white;
  }
}
