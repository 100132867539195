@import 'styles/variables-p44.scss';

.CarrierFleetListComponent {
  color: $black;
  padding: 0;
  position: relative;

  .refine-button-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    @include media-breakpoint-down(xs) {
      text-align: left;
    }
  }

  .refine-button {
    box-shadow: 0 1px 2px 0 rgba($black, 0.5);
    color: $primary-grey-80;
    cursor: pointer;
    font-family: $font-family-base;
    height: 36px;
    width: 58px;
  }

  @include media-breakpoint-down(xs) {

    .no-results-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 280px;
      justify-content: center;
      width: 100%;
    }
  }
}

.carrierListHeader {
  background-color: rgba($primary-grey-05, 0.6);
  width: 100%;
  padding-bottom: 0;
}

.statusSection {
  @include toRem(font-size, 14px);
  padding-bottom: 1.5rem;
  color: $primary-grey;
}

.blank-carrier-list {
  height: 180px;
}

@include media-breakpoint-down(md) {
  .CarrierFleetListComponent {
    .AppliedFilterList {
      text-transform: uppercase;
    }
  }
}
