@import 'styles/variables-p44.scss';
@value collapsedStackingSpacing: 20px;

.routeTimelineContainer {
  padding: 0;
  margin-bottom: 20px;
}

.sectionTitle {
  @include toRem(font-size, 21px);
  font-weight: $font-weight-normal;
  text-transform: none;
}

.eventAdditionalInfo {
  @include toRem(font-size, 14px);
}

.stopContainer {
  @include toRem(padding-bottom, 13px);
  @include toRem(padding-top, 13px);
  background: $white;
  box-shadow: 0 1px 2px 0 rgba($black, 0.5);
  color: $primary-grey-80;
  margin-bottom: 10px;
  margin-top: 10px;

  img {
    width: 100%;
  }
}

.showHiddenTransitUpdates {
  @include toRem(font-size, 14px);
  color: $primary-grey-80;
  cursor: pointer;
  font-weight: $font-weight-semibold;
  border: none;
  background: none;
  position: relative;
  padding-bottom: 10px;

  &:before {
    position: absolute;
    left: -15px;
    top: -3px;
    content: 'keyboard_arrow_down';
    font-family: 'Material Icons';
    font-size: 2rem;
    height: 18px;
    width: 20px;
  }
}

.showHiddenTransitUpdatesOpen {
  &:before {
    content: 'keyboard_arrow_up';
  }
}
.legEventComponent {
  transition: all 0.5s;
  overflow-y: hidden;
  position: relative;
}

.showHiddenShipmentsButton {
  @include toRem(font-size, 12px);
  color: $primary-blue;
  cursor: pointer;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  border: none;
  height: 48px;
  background: none;
  position: absolute;
  right: 15px;
  &.shiftLeft {
    @media (min-width: 769px) {
      right: 55px;
    }
  }
}

.showHiddenShipmentsButtonETA {
  @include toRem(font-size, 12px);
  color: $primary-blue;
  cursor: pointer;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  border: none;
  height: 14px;
  background: none;
  position: initial;
  right: 15px;
  &.shiftLeft {
    @media (min-width: 769px) {
      right: 55px;
    }
  }
  margin-top: 1.4rem;
}

.etaShowLessButton {
  display: flex;
  justify-content: flex-end;
}

.collapsedTitleBar {
  @include toRem(font-size, 14px);
  animation: fadeIn 1.5s ease forwards;
  font-weight: $font-weight-bold;
  opacity: 0;
  padding-bottom: 13px;
  padding-top: 13px;
  background: none;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.collapsedTitleBarLink {
  @include toRem(font-size, 14px);
  color: $primary-blue;
  cursor: pointer;
  font-weight: $font-weight-semibold;
  margin-right: 2rem;
  text-transform: uppercase;
}

.collapsedStopContainer {
  composes: stopContainer;
  cursor: pointer;
}

.stopEventDate {
  @include toRem(font-size, 14px);
  font-weight: $font-weight-bold;
  margin-right: 6px;
}

.stopEventTitle {
  @include toRem(font-size, 14px);
  font-weight: $font-weight-bold;
  margin-right: 6px;
}

.stopEventTime {
  @include toRem(font-size, 14px);
  font-weight: $font-weight-bold;
  white-space: nowrap;
}

.removeStopEvent {
  font-weight: $font-weight-normal;
}

.stopTimeSection {
  line-height: 18px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.stopType {
  @include toRem(font-size, 14px);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.stopDetails {
  padding: 15px 0;

  h3 {
    font-weight: $font-weight-semibolder;
    margin-bottom: 5px;
    max-width: 75%;
  }
}

.stopName a {
  @include toRem(font-size, 16px);
  color: $p44-blue;
  font-weight: $font-weight-bold;
}

.stopModeIconSection {
  margin-top: 20px;
  width: 100%;

  div,
  button {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    &:last-child {
      margin-bottom: 30px;
    }
  }

  button {
    border: none;
    background: none;
    padding: 0;

    &.clickable {
      cursor: pointer;
    }
  }
}

.modeIcon {
  opacity: 0.6;

  img {
    max-width: 65px;
    min-width: 24px;
  }
}

.appointmentWindowContainer {
  padding-right: 5px;
}

.appointmentWindowDivider {
  content: '';
  border-bottom: 1px solid $primary-grey-20;
  margin: 15px 0;
}

.additionalAppointmentWindowsTitle {
  margin-bottom: 10px;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  @include toRem(font-size, 14px);
}

.appointmentWindowTitle {
  display: flex;
  justify-content: space-between;
  @include toRem(font-size, 14px);
  margin-bottom: 5px;
  font-weight: $font-weight-semibold;
  margin-top: 15px;
  span {
    text-transform: capitalize;
  }
}

.appointmentWindowText {
  @include toRem(font-size, 14px);
}

.actualText {
  display: flex;
  justify-content: flex-end;
}

.showApptWindowsButton {
  margin-top: 5px;
  padding: 10px 0;
  font-weight: $font-weight-semibold !important;
  text-transform: uppercase !important;
}

.downArrow {
  background-image: url('../../../assets/images/arrow-down.svg');
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 18px;
  margin-bottom: 4px;
  margin-top: 6px;
  max-width: 65px;
  opacity: 0.6;
  width: 100%;
}

.routeLine {
  background: $primary-grey-40;
  flex: 1;
  height: 100%;
  margin: auto;
  min-height: 48px;
  position: relative;
  width: 2px;
}

.routeLineDotted::after {
  background-color: $primary-grey-05;
  border-radius: 50%;
  content: '';
  height: 6px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
}

.routeLineWithStop::after {
  background-color: $primary-grey-40;
  border-radius: 50%;
  content: '';
  height: 8px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
}

.halfTop {
  height: 24px;
  top: 50%;

  &::after {
    height: 12px;
    top: 0;
    width: 12px;
  }
}

.halfBottom {
  height: 24px;
  top: 0;

  &::after {
    height: 12px;
    top: 80%;
    width: 12px;
  }
}

.routeLineWithStopActive {
  &::before {
    background-color: rgba($primary-blue, 0.3);
    border-radius: 50%;
    content: '';
    height: 50px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
  }

  &::after {
    background-color: rgba($primary-blue, 1);
    border-color: $white;
    border-radius: 50%;
    border-style: solid;
    border-width: 4px;
    content: '';
    height: 20px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
  }

  .horizontal {
    background-color: $white;
    height: 3px;
    left: -14px;
    position: absolute;
    top: calc(50% - 1.5px);
    width: 30px;
  }
}

.lastErrorStatusUpdate {
  &::before {
    background-color: rgba($new-error-red, 0.2);
    border-radius: 50%;
    content: '';
    height: 47px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 47px;
  }

  &::after {
    border-color: $white;
    border-radius: 50%;
    border-style: solid;
    border-width: 13px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .error {  
    left: -11px;
    position: absolute;
    top: calc(50% - 11px);
    z-index: 1;
    width: 9px;
    height: 9px;
  }
}

.errorStatusUpdate {
  &::after {
    border-color: $white;
    border-style: solid;
    border-width: 10px;
  }

  .error {  
    left: -11px;
    position: absolute;
    top: calc(50% - 11px);
    z-index: 1;
  }
}

.stopLocation {
  @include toRem(font-size, 14px);
  color: $primary-grey-80;
  font-weight: $font-weight-normal;
  text-transform: none;

  .stopLocationSeparator {
    padding: 0 5px;
  }
}

.stopCityState {
  white-space: nowrap;
}

.eventLocation {
  font-weight: $font-weight-normal;
  color: $primary-grey-50;
}

.appointmentWindowContent {
  @include toRem(font-size, 14px);
}

.contactInfo {
  @include toRem(font-size, 14px);
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 992px) {
    max-width: 350px;
  }
  @media (max-width: 768px) {
    max-width: 250px;
  }
}

.moveEnter {
  max-height: 0;
  max-width: 0;
  opacity: 0;
  overflow-y: hidden;
}

.moveEnterActive {
  max-height: 500px;
  max-width: 100%;
  opacity: 1;
  transition: all 0.4s;
}

.moveExit {
  max-height: 300px;
  opacity: 1;
  overflow-y: hidden;
}

.moveExitActive {
  max-height: 0;
  opacity: 0;
  transition: all 0.4s;
}

.collapsedRouteLineContainer {
  // calculated since the collapsed stops container is only 80% of total width
  margin-left: 18.75%;
  max-width: 10.29%;
  flex-basis: 10.29%;
}

.styledRouteLine {
  background: $primary-grey-40;
  flex: 1;
  height: 100%;
  margin: auto;
  min-height: 48px;
  position: relative;
  width: 2px;

  &::before {
    color: $primary-grey-500;
    content: '+';
    margin-left: -2rem;
    position: absolute;
    top: calc(50% - 1rem);
  }

  &::after {
    background-color: $primary-grey-40;
    border-radius: 50%;
    border: 3px solid $white;
    content: '';
    height: 14px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
  }
}

.stopStatusContainer {
  @include toRem(font-size, 14px);
  padding-top: 10px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
}

.warningEtaText {
  color: $new-error-red;
}

.ETAstopStatusCard {
  display: flex;
  justify-content: flex-end;
}

.stopStatusCardInfo {
  margin-right: 19px;
  margin-top: -5px;
}

.eventAdditionalInfoStop {
  font-weight: 400;
  text-transform: none;
}
