@import 'styles/variables-p44.scss';

.container {
  width: 100%;
  max-width: 728px;
  position: relative;
}

.title {
  line-height: normal;
}

.icon-skip-next-wrapper {
  color: #00558b;

  .skip-button {
    color: inherit;
    font-weight: 500;
    background-color: transparent;
  }
  .svg-icon-skip {
    fill: #00558b;
    height: 13px;
    width: 12px;
  }
}
