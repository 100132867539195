@import 'styles/variables-p44.scss';

.date-range-container {
  .datepicker-group {
    > * {
      box-shadow: 0 1px 2px 0 rgba($black, 0.5);
    }

    &:first-of-type {
      button {
        margin-right: 10px !important;
      }
    }
  }
}

.date-range-container section.datepicker-group div.range-datepicker div:nth-of-type(1) {
  height: 35.5px !important;
  width: auto !important;
}
