@import 'styles/variables-p44.scss';

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  will-change: width, height;
  z-index: 1;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  will-change: transform;
  z-index: 3;
}

.react-grid-item.react-grid-placeholder {
  border: 4px dotted $bluish;
  transition-duration: 100ms;
  user-select: none;
  z-index: 2;
}

.react-grid-item > .react-resizable-handle {
  bottom: 0;
  cursor: se-resize;
  height: 20px;
  position: absolute;
  right: 0;
  width: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  border-bottom: 2px solid rgba($black, 0.4);
  border-right: 2px solid rgba($black, 0.4);
  bottom: 3px;
  content: '';
  height: 5px;
  position: absolute;
  right: 3px;
  width: 5px;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  background-origin: content-box;
  background-position: bottom right;
  background-repeat: no-repeat;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  bottom: 0;
  box-sizing: border-box;
  cursor: se-resize;
  height: 20px;
  padding: 0 3px 3px 0;
  position: absolute;
  right: 0;
  width: 20px;
}
