@import 'styles/bootstrap/_bootstrap.scss';

.sectionTitle {
  font-weight: $font-weight-normal;
}

.address {
  color: $primary-grey;
  font-weight: $font-weight-normal;
}

.kpiValues {
  font-size: 2.1rem;
}
