@import 'styles/variables-p44.scss';

.quickview-dialog {
  z-index: 1050 !important;
  [role='document'] {
    margin: auto;
    max-width: 800px;
    width: 100%;
  }

  .quickview-actions {
    justify-content: space-between;
  }

  button {
    margin: 0 35px 25px;

    &.delete-button {
      margin: 15px 0;
    }
  }

  h2 {
    @include toRem(font-size, 21px);
    color: $primary-grey;
    font-weight: $font-weight-semibold;
    text-align: center;
  }

  .quickview-container {
    padding: 0 40px;

    .quickview-icon-container {
      margin: 5px -2px;
      position: relative;

      &.selected {
        &:after {
          border-radius: 50%;
          content: '';
          display: block;
          height: 86px;
          left: -3px;
          position: absolute;
          top: -3px;
          width: 86px;
        }
      }

      .quickview-icon {
        cursor: pointer;
        height: 60px;
        margin: 10px;
        width: 60px;
      }
    }

    .FilterComponent {
      background-color: $white;
      border: none;
      box-shadow: none;
      padding: 0;
    }

    .view-results {
      color: $primary-grey-80;
    }

    .refine-header,
    footer,
    .datepicker-row {
      display: none !important;
    }

    h4 {
      @include toRem(font-size, 11px);
      color: $bluish;
      font-weight: $font-weight-semibold;
      padding-top: 3px;
      text-transform: uppercase;
    }

    .form-header {
      @include toRem(font-size, 10px);
      margin-bottom: 22px;
    }

    #statusFilterDropdownItems {
      border: 1px solid $white-two;
      box-shadow: none;

      button {
        border-left: 1px solid $white-two !important;
        box-shadow: none !important;
        height: 34px !important;

        svg {
          height: 22px !important;
          width: 22px !important;
        }
      }
    }

    .checkbox-container {
      // display: flex;
      // flex-direction: row;
      // flex-wrap:wrap;
      // margin-bottom: 18px;

      .checkbox-row {
        flex: 1 0 50%;

        &.happening-today {
          flex: 1 0 100%;
          margin-bottom: 12px;
        }

        .check-list {
          label {
            color: $primary-grey-80 !important;
          }

          svg {
            fill: $bluish !important;
          }

          * .ant-checkbox-wrapper + span,
          * .ant-checkbox + span {
            padding-right: 0;
          }
        }
      }
    }

    .date-selector {
      background-color: $primary-grey-05;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
      display: inline-block;

      > div {
        @include toRem(font-size, 12px);
        border-right: 1px solid $primary-grey-40;
        color: $primary-grey-40;
        cursor: pointer;
        float: left;
        padding: 10px 20px 10px 25px;
        text-transform: uppercase;

        &.selected {
          color: $primary-grey-80;
          font-weight: $font-weight-bold;
        }
      }
    }

    .filter-row {
      .filter-container {
        border: 1px solid $white-two;
        box-shadow: none;

        button {
          border-left: 1px solid $white-two !important;
          box-shadow: none !important;
          height: 34px !important;

          svg {
            height: 22px !important;
            width: 22px !important;
          }
        }

        > :first-child {
          border-right: none !important;
        }
      }
    }
  }
}
