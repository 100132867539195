@import 'styles/variables-p44.scss';

.noteCard {
  @include toRem(font-size, 16px);
  background-color: $primary-grey-05;
  margin: 8px 0;
  padding: 20px 10px 20px 20px;

  &.isActive {
    background: $primary-blue-13;
  }

  &.newNoteCard {
    background: none;
    bottom: 0;
    margin: 20px 0 0;
    padding: 0;
  }

  .initials {
    align-items: center;
    background-color: $primary-grey-20;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 5px 5px 5px 0;
    width: 40px;
  }
}

.notesBody {
  padding-left: 5px;
  width: calc(100% - 50px);

  .createdUser {
    @include toRem(font-size, 12px);
    display: flex;
    position: relative;

    .editIcon {
      background: none;
      border: none;
      color: $primary-blue;
      cursor: pointer;
      line-height: 12px;
    }
  }
}

.buttonsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    align-items: center;
  }

  .deleteLink {
    border: none;
    color: $new-fruit;
    font-weight: $font-weight-semibold;
    padding-left: 0;
    padding-right: 0;
    width: 50%;

    &:hover {
      background: transparent;
      border: none;
      opacity: 0.5;
    }
  }

  .cancelEditNote {
    border: none;
    color: $primary-blue;
    font-weight: $font-weight-semibold;
    padding-left: 0;
    padding-right: 0;
    width: 50%;

    &:hover {
      background: transparent;
      border: none;
      color: $primary-blue-75;
    }
  }
}

.visibilityText {
  @include toRem(font-size, 12px);
}

.visibilityWrapper {
  @include toRem(margin-top, 10px);
  align-items: center;
  display: flex;
  justify-content: space-between;

  .item-title {
    @include toRem(font-size, 12px);
  }

  .checkbox {
    @include toRem(font-size, 12px);
    padding: 0 5px 0 0;

    svg {
      color: $primary-grey-80;
    }
  }
}

.additionalNoteInfo {
  @include toRem(font-size, 12px);
  color: $primary-grey-40;
  cursor: default;
  display: flex;
  font-style: italic;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
}

.noteEdit {
  padding: 5px 0;
}

.messageContent {
  @include toRem(font-size, 14px);
  margin: 20px 0;
}

.noteTitleLine {
  text-transform: uppercase;
}

.visibilityLabel {
  @include toRem(font-size, 12px);
}
