@import 'styles/variables-p44.scss';

.shipment-stop {
  .shipment-stop-form {
    display: none;
  }

  &.expanded {
    .shipment-stop-form {
      display: block;

      .stop-list-row {
        border: none;
      }
    }
  }

  .stop-list-row {
    border-bottom: 1px solid $primary-grey-40;
    display: flex;
    height: 40px;

    > span {
      margin-bottom: auto;
      margin-top: auto;
    }
  }

  .remove-stop {
    @include toRem(font-size, 11px);
    color: $new-fruit;
    cursor: pointer;
    font-weight: $font-weight-semibold;
    margin-left: auto;
    margin-right: 15px;
    padding-right: 15px;
    position: relative;
    text-transform: uppercase;

    i {
      @include toRem(font-size, 12px);
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .company-title {
    @include toRem(font-size, 14px);
    color: $primary-grey-80;
    font-weight: $font-weight-bold;
  }

  .display-stop {
    cursor: pointer;
  }
}
