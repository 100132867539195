@import 'styles/modules/_mixins.scss';
@import 'styles/modules/_colors.scss';
.noHoursAvailable {
  color: $primary-grey-40;
  line-height: 2.5rem;
  font-size: 1.4rem;
  padding: 0 1rem;
}

.headerButtons {
  text-align: right;

  @include media-breakpoint-down(md) {
    margin-top: 1rem;
    text-align: left;
  }
}

.link {
  @include toRem(font-size, 14px);
  color: $primary-blue;
  font-weight: bold;
}
