@import 'styles/variables-p44.scss';

.contact-info {
  margin: 15px 0;
}

.toggle-contact-info {
  label {
    @include toRem(font-size, 10px);
    color: $teal;
    cursor: pointer;
    display: inline-block;
    padding-left: 20px;
    position: relative;
  }

  i {
    @include toRem(font-size, 17px);
    left: 0;
    position: absolute;
  }
}
