@import 'styles/variables-p44.scss';

$nib-size: 12px;

.app-menu-container {
  position: relative;

  .app-menu-icon {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  .action-window {
    background-color: $white;
    box-shadow: 0 1px 8px 0 rgba($black, 0.5);
    display: flex;
    flex-wrap: wrap;
    left: 25%;
    padding: 4px;
    position: absolute;
    top: 40px;
    transform: translate(-50%);
    transition: all 250ms ease-in-out;
    width: 168px;
    z-index: 3;

    @include media-breakpoint-down(md) {
      left: 0;
      transform: translate(-70%);
    }

    .action-window-row {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    .action-window-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-wrap: nowrap;
      height: 80px;
      justify-content: center;
      margin: 0;
      width: 80px;

      &:hover {
        background-color: $primary-grey-05;
        text-decoration: none;
      }
    }

    .icon-attributes {
      height: 36px;
      object-fit: contain;
      width: 36px;
    }

    .icon-text {
      @include toRem(font-size, 10px);
      padding-top: 5px;
      text-transform: uppercase;
    }

    &:before {
      border-color: transparent transparent $white transparent;
      border-style: solid;
      border-width: 0 $nib-size $nib-size $nib-size;
      content: '';
      filter: drop-shadow(0 -2px 1px rgba($black, 0.2));
      left: calc(50% - #{$nib-size});
      position: absolute;
      top: -$nib-size;
      z-index: 3;

      @include media-breakpoint-down(md) {
        left: calc(75% - 8px);
      }
    }
  }
}
