@import 'styles/variables-p44.scss';

$nib-size: 12px;

.user-menu-container {
  position: relative;

  .user-action-window {
    background-color: $white;
    box-shadow: 0 1px 8px 0 rgba($black, 0.5);
    position: absolute;
    text-align: center;
    top: 40px;
    transition: all 250ms ease-in-out;
    right: calc(100% - (24px + #{$nib-size})); // offset icon + nib
    z-index: 3;

    @include media-breakpoint-down(md) {
      right: calc(100% - (#{$nib-size} * 3));
    }

    &:before {
      border-color: transparent transparent $white transparent;
      border-style: solid;
      border-width: 0 $nib-size $nib-size $nib-size;
      content: '';
      filter: drop-shadow(0 -2px 1px rgba($black, 0.2));
      left: calc(100% - (24px + #{$nib-size})); // offset icon + nib
      position: absolute;
      top: -$nib-size;
      z-index: 3;

      @include media-breakpoint-down(md) {
        left: calc(100% - (#{$nib-size} * 3));
      }
    }

    .user-action-window__greeting {
      padding: 8px 12px;
      text-align: left;
      text-transform: uppercase;
    }

    .user-action-window-row {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 8px;

      &:not(:last-child) {
        border-bottom: 1px solid $white-two;
      }

      &:hover {
        text-decoration: none;
        background-color: $primary-grey-05;
      }

      .menu-item-separator {
        margin: 0;
        border-top: 1px solid $white-two;
      }

      .user-icon-text {
        min-width: 80px;
        @include toRem(font-size, 14px);
      }
    }

    .user-action-pointer:hover {
      cursor: pointer;
    }
  }

  .user-menu-icon {
    width: 28px;
    height: 28px;
  }
}
