@import 'styles/variables-p44.scss';

.CarrierDetail {
  flex: 1;

  h2 {
    @include toRem(font-size, 16px);
    font-weight: $font-weight-normal;
    text-align: center;
  }

  .header-label {
    @include toRem(font-size, 14px);
    padding-right: 5px !important;
  }

  .header-value {
    @include toRem(font-size, 14px);
    padding-left: 5px !important;
  }

  @media only screen and (max-width: 559px) {
    .carrier-info {
      @include toRem(font-size, 12px);

      .header-label {
        @include toRem(font-size, 10px);
        color: $primary-grey-80;
        height: 12px;
        width: 80px;
      }

      .header-value {
        color: $primary-grey-80;
      }
    }
  }

  @media only screen and (min-width: 559px) {
    .carrier-info {
      table tbody tr td {
        &:nth-of-type(1) {
          @include toRem(font-size, 12px);
          color: $primary-grey-80;
          font-weight: $font-weight-light;
        }

        &:nth-of-type(2) {
          width: 1em;
        }

        &:nth-of-type(3) {
          @include toRem(font-size, 12px);
          color: $primary-grey-80;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.CarrierDetail--ltl {
  @extend .CarrierDetail;
  align-items: center;
  justify-content: center;

  .header-label {
    padding-right: 5px !important;
  }

  .header-value {
    padding-left: 5px !important;
  }
}

.CarrierDetail--ocean {
  @extend .CarrierDetail;

  .header-label {
    padding-right: 5px !important;
  }

  .header-value {
    padding-left: 5px !important;
  }
}
