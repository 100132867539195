@import 'styles/variables-p44.scss';

$map-popup-padding: 15px;

.mapPopupWrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;
  font-family: $font-family-base; // overrides mapbox's included font-family
  left: 20px;
  min-width: 225px;
  position: absolute;
  top: 85px;
}

.mapPopupInner {
  padding: $map-popup-padding;
  display: flex;
  flex-direction: column;
}

.header {
  @include toRem(font-size, 16px);
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  height: 24px;
  margin-left: 15px;
  outline: none;
  padding: 0;
  width: 24px;
}

.stop {
  display: flex;
  margin-bottom: 5px;
}

%teardrop {
  background-color: $bluish;
  border-radius: 0 50% 50% 50%;
  border: 3px solid $bluish;
  height: 20px;
  margin: 5px 0;
  position: relative;
  transform: rotate(-135deg);
  width: 20px;

  &:after {
    background-color: $white;
    border-radius: 50%;
    content: '';
    height: 6px;
    left: calc(50% - 3px);
    position: absolute;
    top: calc(50% - 3px);
    width: 6px;
  }
}

.originIcon {
  @extend %teardrop;

  &:after {
    background-color: $green;
  }
}

.destinationIcon {
  @extend %teardrop;

  &:after {
    background-color: $new-fruit;
  }
}

.stopText {
  @include toRem(font-size, 14px);
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.stopName {
  font-weight: $font-weight-bold;
}

.viewButton {
  justify-content: space-around;
  margin: 10px;
  width: calc(100% - 20px);
}

.updatedText {
  font-style: italic;
}

.shareBadge {
  padding: 10px $map-popup-padding;
  background-color: rgba($primary-grey-05, 0.6);
}
