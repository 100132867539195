@import 'styles/variables-p44.scss';

.route-new-day {
  height: 38px;

  &__date {
    @include toRem(font-size, 14px);
    color: $primary-grey-80;
    font-weight: $font-weight-bold;
    text-align: right;
    text-transform: uppercase;
    width: 120px;
  }

  &__line {
    height: 38px;
    width: 110px;

    .vertical-line {
      background: $primary-grey-35;
      display: flex;
      flex: 1;
      height: 100%;
      margin: auto;
      position: relative;
      width: 2px;
    }
  }

  &__hr {
    background-color: $primary-grey-30;
    flex: 1;
    height: 2px;
  }
}

@include media-breakpoint-down(xs) {
  .route-new-day {
    &__date {
      order: 2;
      text-align: left;
    }

    &__line {
      order: 1;
      width: 50px;
    }

    &__hr {
      order: 3;
    }
  }
}
