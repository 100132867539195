/*!
 * Project 44 - Custom Bootstrap
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 */

$grid-columns: 24 !default;
$input-height-lg: 36px;

// $grid-gutter-width:           30px !default;

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import './variables-p44';

$dropdown-link-active-bg: lighten($primary-blue-45, 20%);
$dropdown-link-active-color: $grey;

@import '../../../node_modules/bootstrap/scss/mixins';
// @import '../../../node_modules/bootstrap/scss/root';
// @import '../../../node_modules/bootstrap/scss/reboot';
// @import '../../../node_modules/bootstrap/scss/type';
// @import '../../../node_modules/bootstrap/scss/images';
// @import '../../../node_modules/bootstrap/scss/code';

@import '../../../node_modules/bootstrap/scss/grid';

// @import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/forms'; // Remove in the future
// @import '../../../node_modules/bootstrap/scss/buttons';
// @import '../../../node_modules/bootstrap/scss/transitions';
@import '../../../node_modules/bootstrap/scss/dropdown';
// @import '../../../node_modules/bootstrap/scss/button-group';
@import '../../../node_modules/bootstrap/scss/input-group'; // Remove in the future
@import '../../../node_modules/bootstrap/scss/custom-forms'; // Remove in the future
// @import '../../../node_modules/bootstrap/scss/nav';
// @import '../../../node_modules/bootstrap/scss/navbar';
// @import '../../../node_modules/bootstrap/scss/card';
// @import '../../../node_modules/bootstrap/scss/breadcrumb';
// @import '../../../node_modules/bootstrap/scss/pagination';
// @import '../../../node_modules/bootstrap/scss/badge';
// @import '../../../node_modules/bootstrap/scss/jumbotron';
// @import '../../../node_modules/bootstrap/scss/alert';
// @import '../../../node_modules/bootstrap/scss/progress';
// @import '../../../node_modules/bootstrap/scss/media';
// @import '../../../node_modules/bootstrap/scss/list-group';
// @import '../../../node_modules/bootstrap/scss/close';
// @import '../../../node_modules/bootstrap/scss/toasts';
// @import '../../../node_modules/bootstrap/scss/modal';
// @import '../../../node_modules/bootstrap/scss/tooltip';
// @import '../../../node_modules/bootstrap/scss/popover';
// @import '../../../node_modules/bootstrap/scss/carousel';
@import '../../../node_modules/bootstrap/scss/spinners';
@import '../../../node_modules/bootstrap/scss/utilities';
// @import '../../../node_modules/bootstrap/scss/print';
