.input-row {
  display: flex;
  > * {
    flex-grow: 1;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    margin: 0 5px 8px 5px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.select-dropdown {
  .invalid-feedback {
    display: block;
  }
}

.datepicker-group hr {
  display: none;
}

@media only screen and (max-width: 799px) {
  .add-edit-shipment {
    width: 100%;
  }
  .add-edit-shipment-form .input-row {
    flex-direction: column;
  }
  .add-edit-shipment-form .input-row > * {
    margin: 0 0 17px;
  }
  .add-edit-shipment-form .input-row.date-time-row {
    flex-direction: row;
    flex-flow: row wrap;
  }
  .add-edit-shipment-form .input-row.date-time-row > .input-container {
    width: 50%;
  }
  .add-edit-shipment-form .input-row.date-time-row .date-time-separator {
    display: none;
  }
}

