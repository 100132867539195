@import 'styles/variables-p44.scss';

.shipment-stops {
  position: relative;
  &__line {
    background: $primary-grey-20;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &-colored {
      display: flex;
      flex-direction: row;
      height: 4px;
      justify-content: space-around;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  &__stops {
    align-items: flex-start;
    display: flex;
    justify-content: space-around;

    .shipment-stop {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -4px;

      &__status-dot {
        background-color: #bbb;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        z-index: 5;
      }

      &__popover {
        cursor: pointer;
        position: relative;

        &:nth-child(2) {
          margin-left: -40px;
        }

        &:nth-last-child(3) {
          margin-right: -40px;
        }

        .shipment-stop {
          &__status-dot {
            position: relative;
            transition: 200ms ease-in-out;

            &:hover {
              // Increases size of circle without changing spacing in flex row
              &:after {
                border-radius: 50%;
                content: '';
                height: 16px;
                left: -2px;
                position: absolute;
                top: -2px;
                transition: 200ms ease-in-out;
                width: 16px;
              }
            }
          }
        }
      }

      &__info {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-start;
        margin-top: 10px;
        position: relative;
        text-align: center;
      }

      &__name {
        @include toRem(font-size, 16px);
      }

      &__location {
        @include toRem(font-size, 14px);
        text-transform: uppercase;
      }

      &__tertiary {
        @include toRem(font-size, 14px);
        margin-top: 6px;
        max-width: 155px;
      }
    }
  }
}

.ant-popover-inner-content {
  .shipment-stop {
    &__info {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
      position: relative;
    }

    &__name {
      @include toRem(font-size, 16px);
      white-space: nowrap;
    }

    &__location {
      @include toRem(font-size, 12px);
      text-transform: uppercase;
      white-space: nowrap;
    }

    &__tertiary {
      @include toRem(font-size, 10px);
      margin-top: 3px;
      white-space: nowrap;
    }
  }
}

@include media-breakpoint-down(xs) {
  .shipment-details {
    .shipment-status {
      &__stops {
        margin-bottom: 4rem !important;
      }

      &__flag {
        @include toRem(font-size, 16px);
        margin: 0 24px 10px 24px !important;
      }

      &__forecast-text {
        @include toRem(font-size, 16px);
        color: $primary-grey;
        text-transform: uppercase;
      }

      &__last-update {
        @include toRem(font-size, 12px);
        font-style: normal !important;
        font-weight: $font-weight-normal;
        padding-top: 5px;
        text-align: center !important;
      }

      .shipment-stops {
        .shipment-stop {
          &__info {
            display: none;
          }
        }
      }
    }
  }
}
