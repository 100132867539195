@import 'styles/variables-p44.scss';

.infoBar {
  align-items: center;
  background-color: $active-background-color;
  display: flex;
  margin-bottom: 30px;
  padding: 15px;
}

.icon {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}

.text {
  @include toRem(font-size, 16px);
  margin-left: 12px;
}
