@import 'styles/variables-p44.scss';

.helpText {
  margin-left: 45px;

  @media (max-width: 1075px) {
    margin-bottom: 40px;
    margin-left: 0;
    margin-top: 40px;
  }
}

.text {
  @include toRem(font-size, 12px);
  color: $primary-text-color;
  margin-bottom: 18px;
}

.examplesWrapper {
  align-items: center;
  display: flex;
  padding-bottom: 6px;
  position: relative;
}

%example {
  @include toRem(font-size, 29px);
  align-items: center;
  background-color: $secondary-background-color;
  border: 1px solid $light-border-color;
  color: $primary-text-color;
  display: flex;
  font-style: italic;
  font-weight: $font-weight-bold;
  justify-content: center;
}

.badExample {
  @extend %example;
  height: 100px;
  width: 150px;
}

.goodExample {
  @extend %example;
  height: 37px;
  margin-left: 35px;
  width: 105px;
}

.circle {
  background-color: $primary-text-color;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

.icon {
  bottom: -6px;
  height: 24px;
  position: absolute;
  width: 24px;
}

.validLogo,
.validLogo g {
  fill: $success-color;
}

.invalidLogo,
.invalidLogo g {
  fill: $error-color;
}
