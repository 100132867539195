@import '../modules/mixins';
@import '../modules/styles';
@import '../modules/colors';
@import 'styles/variables-p44.scss';

// layouts

.card-container {
  @include toRem(margin-bottom, 10px);
  @include toRem(padding, 15px);
  background-color: $white;
  box-shadow: $boxShadow;

  &.row {
    @include toRem(padding, 15px 0);
  }
}

.list-card-container {
  @include toRem(padding, 15px);
  @include toRem(margin-bottom, 12px);
  background-color: $white;
  box-shadow: $boxShadow;
}

.wrap-card-container {
  @include toRem(padding, 15px);
  background-color: $white;
  box-shadow: $boxShadow;
}

.section-content {
  @include toRem(margin, 20px auto);
}

.no-results-message {
  margin: 70px auto;
  text-align: center;

  h4 {
    color: $primary-grey-80;
    font-size: 24px;
    font-weight: $font-weight-semibold;
  }

  i {
    color: $primary-grey-40;
    font-size: 72px;
    margin: 15px auto;
  }

  .no-results-image {
    height: 72px;
    margin: 15px auto;
    width: 72px;
  }

  p {
    color: $primary-grey-80;
  }
}

.snackbar-container {
  top: 29px !important;

  .snack-message {
    @include toRem(font-size, 13px);
    align-items: center;
    display: flex;
    text-transform: uppercase;

    i {
      margin-right: 10px;
    }
  }

  button {
    height: 24px;
    width: 24px;

    i {
      color: $white;
      line-height: 0;
      vertical-align: top;
    }
  }

  &.snackbar-success > div {
    background-color: $green;
  }
}

.has-shadow {
  box-shadow: $boxShadow;
}

// for contrasting dark backgrounds

.contrast-background {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
  .form-group .form-control {
    box-shadow: $boxShadow;
  }
  .form-header {
    color: $white;
  }
}

@include media-breakpoint-down(md) {
  .container {
    max-width: none !important;
    width: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .sub-header {
    .mobile-column h1 {
      padding-bottom: 20px;
    }

    h1 {
      font-size: 24px;
    }
  }
}
