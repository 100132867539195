@import 'styles/variables-p44.scss';

.document-stage-component-wrapper {
  background-color: rgba(45, 41, 38, 0.5);
  z-index: 11;
  position: fixed;
  inset: 0px;
  display: flex;
}

.document-stage-component {
  z-index: 11;
  width: 768px;
  min-height: 120px;
  background-color: $secondary-background-color;
  box-shadow: 0 2px 8px 0 rgba($black, 0.5);
  padding: 20px;
  margin: auto;
  transition: all 250ms ease-in-out;
}

.document-staged-section-header {
  font-weight: bold;
}

.document-type-input {
  &:focus &:active {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.document-staged-file-list {
  overflow-y: auto;
}

.document-staged-file-row {
  display: flex;
  justify-content: space-between;
}

.document-staged-file-name {
  display: flex;
  align-self: center;
  outline: 1px solid $primary-grey-20;
  width: 100%;
  margin-left: 1px;
  margin-top: 5px;
  font-size: 14px;
  padding: 8px;
}

.auto-complete {
  background-color: yellow;
}

.buttons-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.invalid-file-type {
  color: red;
}

.disabledSubmit {
  color: $primary-grey-80;
  cursor: default;

  &:hover {
    color: $primary-grey-80;
  }
}
