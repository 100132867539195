@import 'styles/variables-p44.scss';

.FleetListHeaderComponent {
  flex: 1;
}

.backToFindCarrier {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  color: $primary-blue;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
}

.link {
  @include toRem(font-size, 12px);
  color: $primary-blue;
  display: inline-block;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  border: none;
}

@include media-breakpoint-down(md) {
  .FleetListHeaderComponent {
    background-color: $white;
    flex-direction: column;

    div.fleet-list-header-button-container {
      position: relative;
    }

    h1 {
      text-align: center;
    }

    .SearchBar {
      flex-grow: 1;
      padding-left: 0;
      padding-right: 0;

      button {
        background: $white;
        border: none;
        box-shadow: none;

        &:hover,
        &:focus {
          background: $white;
        }

        .anticon-search {
          color: $primary-grey-80;
        }
      }

      input {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .FleetListHeaderComponent {
    align-items: center;
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
