@import 'styles/variables-p44.scss';

:export {
  primaryColor: $primary-blue;
}

$hereMapHeight: 550px;

.mapWrapper {
  height: $hereMapHeight;
}

.map {
  background-color: $grey;
  height: 100%;
  width: 100%;
}
