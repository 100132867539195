.sub-header {
  padding: 25px 0;
  background-size: 55px;
  margin-top: 4px;
  background-color: #fff;
  position: relative;
  .ant-row-flex {
    flex-flow: row;
    .p44-container {
      .BackButton {
        position: absolute;
        top: -20px;
      }
      h1 {
        margin: 0;
      }
    }
  }
}
