@import 'styles/variables-p44.scss';

.stops {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: 55px;
}

.stop {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.modeIcon {
  position: absolute;
  right: -35px;
  top: -40px;
  width: 70px;
}

.label {
  @include toRem(font-size, 14px);
  padding-top: 6px;
  line-height: 1.33;
  text-align: center;
  max-width: 180px;
}

.location {
  @include toRem(font-size, 14px);
}
