@import 'styles/variables-p44.scss';

.document-upload-component-box {
  background-color: $secondary-background-color;
  display: flex;
  flex-direction: column;
}


.document-upload-section {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
}

.dropzoneWrapper {
  display: flex;
  flex-direction: column;
}

.dropzone {
  align-items: center;
  border: 2px dashed $dark-border-color;
  display: flex;
  flex-shrink: 0;
  height: 115px;
  justify-content: space-around;
  outline: none;
  overflow: hidden;
  transition: .2s background-color;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
}

.document-upload-requirement-text {
  @include toRem(font-size, 12px);
  color: $primary-text-color;
  display: flex;
  justify-content: space-between;
}

.errorMessage {
  @include toRem(font-size, 12px);
  color: $error-color;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 70%;
}
