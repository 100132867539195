@import 'styles/variables-p44.scss';
@import 'styles/modules/_colors.scss';

.documentDetail {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
  margin-right: 2%;
  padding: 2rem;
  width: 32%;

  &:focus {
    text-decoration: none;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  .label {
    @include toRem(font-size, 16px);
    color: $primary-text-color;
    font-weight: $font-weight-bold;
  }

  &:hover {
    .label,
    g {
      color: $active-text-color;
      fill: $active-text-color;
    }
  }
}

.scope-label {
  font-weight: $font-weight-semibold;
}

.download-button {
  background-color: unset;
  border: unset;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

.file-labels {
  font-weight: 700;
  color: $primary-grey-50;
}

.icon {
  height: 24px;
  width: 24px;
}
