@import 'bootstrap/bootstrap';
@import '~tailwindcss/dist/utilities.min.css';
@import 'partials/forms';
@import 'partials/base';
@import 'partials/buttons';
@import 'partials/layouts';
@import 'vendor/animate';
@import 'vendor/react-grid-layout';
@import 'modules/typography';

html {
  padding: 0;
  margin: 0;
}

#root {
  display: inline;
}

// For some reason Last Pass will sometimes place an icon on the body and in the input box causing two to show up
// This hides the icons it places on the body
img#__lpform_password_icon,
img#__lpform_username_icon,
img#__lpform_newPassword_icon,
img#__lpform_confirmNewPassword_icon {
  display: none;
}

.App {
  display: inline;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;

  > div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  > div.ie-banner {
    min-height: auto;
  }
}

.App-logo {
  height: 35px;
}

.App-header {
  align-items: center;
  background-color: $white;
  box-shadow: 0 2px 2px 0 rgba($black, 0.24);
  display: flex;
  flex-direction: row;
  height: 64px;
  padding: 0 30px;

  nav ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    list-style-type: none;

    li {
      margin: 0 1em 0 0;
    }
  }
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

// Set Icons to the top of message text
.ant-message .anticon {
  vertical-align: top;
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  border: 1px solid $white-two;

  .ant-input {
    border: none;
  }
}

hr {
  border-top: 1px solid $primary-grey-20;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

// Fixes issue caused by us having border-radius 0 on modals
.ant-modal-content {
  background-clip: border-box;
}

/* legacy color palette */
:root {
  --primary-grey: #2d2926;
  --primary-grey-80: #575451;
  --primary-grey-40: #aba9a8;
  --primary-grey-20: #d5d4d4;
  --primary-grey-05: #f3f3f3;
  --mysterious-grey: #aba9a8;
  --white: #ffffff;
  --white-two: #d5d4d4;
  --green: #00a651;
  --dark-green: #00854a;
  --teal: #1296b0;
  --bluish: #00aeef;
  --chathams-blue: #07456b;
  --p44-blue: #00558b;
  --off-blue: #5888b0;
  --primary-blue: #00558b;
  --primary-blue-95: #2e6998;
  --primary-blue-85: #236192;
  --primary-blue-75: #5a89ae;
  --primary-blue-45: #9cb8ce;
  --primary-blue-30: #bdd0df;
  --primary-blue-13: #e3ebf1;
  --new-lemon: #f0c825;
  --yellow: #ffcb05;
  --orange: #f7941d;
  --michelangelo-orange: #ffab36;
  --new-fruit: #f04e37;
}

// override the class coming from the Typeahead component. It's causing an a11y violation
.rbt-input-hint {
  color: $primary-grey !important;
}

.ant-form-item-extra {
  color: $grey;
}

.sendbird-modal {
  z-index: 2;
}

.sendbird-dropdown__reaction-bar {
  z-index: 10;
}
