@import 'styles/variables-p44.scss';
@import 'styles/bootstrap/_bootstrap.scss';
.port-congestion-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  .section-content {
    min-height: 600px;
    position: relative;
    padding-left: 100px;
  }
}
