@import 'styles/variables-p44.scss';

.controlsPreview {
  background-color: $primary-background-color;
  padding-left: 38px;
  padding-top: 28px;
}

.navbar {
  background-color: $secondary-background-color;
  box-shadow: 0 2px 2px 0 rgba($black, 0.24);
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-right: 28px;
  overflow: hidden;
}

.logoAndMenuWrapper {
  align-items: center;
  display: flex;
}

.logo {
  height: 35px;
  margin-left: 30px;
  margin-right: 40px;
  max-width: 103px;
  object-fit: contain;
}

.menu {
  align-items: center;
  display: flex;
}

.menuItem {
  @include toRem(font-size, 14px);
  border-bottom: 5px solid transparent;
  color: $primary-text-color;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: space-around;
  padding: 0 20px;

  &.selected {
    font-weight: $font-weight-bold;
  }
}

.icons {
  align-items: center;
  display: flex;
  margin-right: 10px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.icon {
  margin-right: 20px;
}

.buttonAndFooterWrapper {
  display: flex;
}

.button {
  transition: none !important;
}

.footer {
  color: $light-text-color;
  display: flex;
  flex: 1;
  height: 200px;
  margin-left: 28px;
  overflow: hidden;
  padding-left: 40px;
  padding-top: 40px;
}

.footerGroup {
  padding: 0 15px;
  width: 33%;
}

.footerGroupName {
  @include toRem(font-size, 14px);
  font-weight: $font-weight-bold;
}

.footerLink {
  font-weight: $font-weight-lighter;
  margin-top: 20px;
}
