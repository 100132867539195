@import 'styles/variables-p44.scss';

.carrier-type-selector {
  background-color: $primary-grey-05;
  box-shadow: 0 1px 2px 0 rgba($black, 0.5);
  display: inline-block;

  .carrier-selection {
    @include toRem(font-size, 12px);
    border-right: 1px solid $primary-grey-40;
    color: $primary-grey-40;
    cursor: pointer;
    float: left;
    padding: 10px 20px 10px 25px;

    i {
      display: none;
    }

    &.selected {
      color: darken($primary-grey-40, 33%); // ~#565451;
      position: relative;

      i {
        @include toRem(font-size, 20px);
        display: block;
        left: 5px;
        position: absolute;
        top: 7px;
      }
    }
  }
}

.tracking-method {
  p {
    @include toRem(font-size, 12px);
    margin-bottom: 15px;
  }

  .carrier-text {
    font-weight: $font-weight-bold;
  }
}

.temperature-choice {
  text-align: center;
}
