@import 'styles/variables-p44.scss';

.shareWindow {
  background-color: $secondary-background-color;
  box-shadow: 0 2px 8px 0 rgba($black, 0.5);
  min-height: 120px;
  padding: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 16px);
  transition: all 250ms ease-in-out;
  width: 440px;
  z-index: 10;

  @include media-breakpoint-down(xs) {
    top: 64px;
    width: 100%;
  }

  &:before {
    border-left-color: $secondary-background-color;
    border-top-color: $secondary-background-color;
    border: 10px solid transparent;
    content: '';
    filter: drop-shadow(0 -2px 1px rgba($black, 0.25));
    height: 0;
    position: absolute;
    right: 30px;
    top: -9px;
    transform: rotate(45deg);
    width: 0;
    z-index: 3;

    @include media-breakpoint-down(xs) {
      right: 25px;
    }
  }
}

.spinnerWrapper {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.spinner {
  margin: 0 auto;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.headerText {
  @include toRem(font-size, 16px);
  font-weight: $font-weight-semibold;
}

.generateLink {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 20px;
  outline: none;
  background: none;
  border: none;
}

.generateLinkIcon {
  border-radius: 50%;
  fill: $light-text-color;
  height: 18px;
  margin-left: 4px;
  width: 18px;
}

.generateLinkText {
  line-height: 20px;
}

.subHeader {
  @include toRem(font-size, 14px);
  margin-bottom: 8px;
}

.publicUrlWrapper {
  display: flex;
  margin: 12px 0;
}

.copyButton {
  // Overrides the default button styles for this special case
  height: 34px !important;
  margin-left: 4px;
}

.buttonsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.doneButton {
  margin-left: auto;
}

.inputWrapper {
  margin-bottom: 8px;
}
