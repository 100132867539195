@import 'styles/variables-p44.scss';

.hiddenExpandIcon .ant-table-row-expand-icon-cell button {
  display: none !important;
}

.shaded {
  background-color: $primary-grey-05 !important;
}

.primaryColumnValue {
  font-weight: $font-weight-bold;
}
.no-documents-message {
  color: darken($primary-grey-50, 75%);
}

.infoPopover {
  @include toRem(font-size, 24px);
  color: $primary-blue;
}

.customExpandIcon {
  @include toRem(font-size, 18px);
  color: $primary-blue;
  font-weight: $font-weight-bold;
}

.TLDetailsComponent {
  h2:not(.light) {
    font-weight: $font-weight-normal;
    margin-bottom: 10px;
  }

  .mobile-sub-header h1 {
    margin: 0;
  }

  .sub-header {
    h3 {
      margin-bottom: 0;
    }
  }

  header.shipment-details-header {
    background: $white;
    display: flex;
    flex-direction: column;

    .shipment-details-header-content {
      align-self: center;
      padding: 0.5em 0 0.5em 2em;
      width: 100vw;

      strong {
        color: $primary-grey;
        font-family: $font-family-base;
        font-size: 0.8em;
        font-weight: $font-weight-bold;
      }
    }
  }

  .add-edit-shipment-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;

    .material-icons {
      @include toRem(font-size, 15px);
      margin-top: -1px;
    }

    button {
      background-color: transparent !important;
    }
  }

  .route-detail-expanded {
    .add-edit-shipment-container {
      right: -14px;
      top: -10px;

      button {
        min-width: 71px !important;
      }
    }
  }

  .shipment-details-content {
    margin-bottom: 19px;
    margin-left: -6px;
    margin-right: -6px;

    > div {
      // shipment details columns
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}

.shipmentAttributeLabel {
  @include toRem(font-size, 16px);
}

.shipmentAttributeValue {
  @include toRem(font-size, 21px);
}
