.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: var(--primary-blue-30);
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  background-color: var(--primary-blue-30);
}

.ant-dropdown-link {
  cursor: pointer;
}
