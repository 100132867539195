@import 'styles/variables-p44.scss';

.description {
  margin-bottom: 24px;
  @include toRem(font-size, 12px);
}

.editIcon {
  @include toRem(font-size, 14px);
  margin-right: 2px;
}

.errorMessage {
  display: block;
  color: $new-fruit;
  @include toRem(font-size, 12px);

  &:first-child {
    margin-top: 8px;
  }
}
