@import 'styles/variables-p44.scss';

.emptyNotes {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.notesListContainer {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow-y: auto;
}

.gradientSpacer {
  background: linear-gradient($white, transparent);
  height: 20px;
  position: absolute;
  top: 84px;
  width: 100%;
}

.publicLoginLink {
  @include toRem(font-size, 13px);
  color: $teal !important;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  width: 41px;
}

.badgeBg {
  background-color: rgba($primary-grey-05, 0.6);
  min-height: 36px;
  padding: 0.5em 1em;
  width: 100%;
}
