@import 'styles/variables-p44.scss';

.ShipmentStatus {
  margin: 0 1em 0 1em;
  max-width: 140px;
  text-align: center;
  width: 100%;

  .status-icon {
    display: flex;
    position: relative;

    svg {
      fill: '$neon-green';
      height: 42px;
      margin-left: -19px;
      width: 38px;
    }
  }

  .status-line {
    background: $primary-grey-20;
    height: 4px;
    width: 100%;

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }
  }

  .colored-status-line {
    height: 4px;
    position: relative;
    top: 4px;
  }
}
