@import 'styles/variables-p44.scss';

.sideMenu {
  height: 100%;

  .sideMenuLink {
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
  }

  .itemsContainer {
    border-bottom: 1px solid $primary-grey-05;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  .sideMenuHeader {
    display: flex;
    flex-direction: row;
    height: 63px;

    .sideMenuLogoSmall {
      height: 36px;
    }

    .sideMenuIcon {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: center;

      img {
        cursor: pointer;
      }
    }

    svg {
      cursor: pointer;
    }

    .closeButtonMenu {
      position: absolute;
      right: 24px;
      top: 20px;
    }
  }

  .sideMenuItems {
    list-style-type: none;
    border-right: none;
    li,
    a {
      @include toRem(font-size, 16px);
      color: $primary-grey;
    }

    li {
      margin-bottom: 20px;
    }

    a {
      display: flex;
      flex-direction: row;
      align-content: center;
    }

    .linkName {
      padding-left: 10px;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }

    // &:first-of-type .sideMenuLink {
    //   border-top: 1px solid $white-two !important;
    // }

    // &:last-of-type .sideMenuLink {
    //   border-bottom: 1px solid $white-two !important;
    // }

    .sideMenuLink {
      @include toRem(font-size, 16px);
      color: $primary-grey;
      max-width: 528px;
      min-height: 48px;
      padding: 0 !important;

      > div > div {
        padding: 8px 24px !important;
      }

      &.sideLinkActive {
        @include toRem(font-size, 15px);
        color: $primary-blue !important;

        > div > div {
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  .footerLinks {
    align-items: flex-end;
    align-self: flex-end;
    margin: auto auto 32px auto;
    max-width: 528px;
    width: 528px;

    .footerRow {
      padding-bottom: 24px;

      .footerHeader {
        @include toRem(font-size, 13px);
        color: $primary-grey-80;
        font-weight: $font-weight-bold;
        padding-bottom: 10px;
      }

      .footerLinkRow {
        padding: 3px 0;

        &:hover {
          color: $bluish;
          text-decoration: underline;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .sideMenu {
    .footerLinks {
      margin: auto auto 32px 24px;
      width: auto;
    }
  }
}
