@import 'styles/modules/_mixins.scss';

.settingsInner {
  display: flex;
  margin: 23px 74px 32px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.referenceDataIcon {
  margin-left: 3px;
  margin-right: 4px;
}
