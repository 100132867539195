@import 'styles/variables-p44.scss';

.add-edit-notification-form {
  max-width: 600px;
}

.ant-select-selection__placeholder {
  &:not(.ant-select-focused) {
    .ant-select-selection__placeholder {
      display: block !important;
    }
  }
}

.notification-rule {
  color: $primary-grey-50;
  margin-bottom: 18px;
}

.notification-rule__chip-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: 35px;

  .notification-chip__container {
    align-items: center;
    background-color: $primary-grey-50;
    color: $white;
    cursor: default;
    display: flex;
    justify-content: center;
    margin: 4px 2px;
    padding: 3px 6px 3px 10px;

    &:first-child {
      margin-left: 4px;
    }
  }

  .notification-chip__delete-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 6px;
    width: 16px;
  }

  .notification-chip__close-icon {
    height: 14px !important;
    width: 14px !important;
  }
}

.add-edit-notification-error {
  color: $error-red;
  margin-bottom: 10px;
}
