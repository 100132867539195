@import 'styles/variables-p44.scss';

.title {
  @include toRem(font-size, 21px);
  color: $primary-text-color;
  margin-bottom: 22px;
}

.pickerWrapper {
  align-items: center;
  display: flex;
}

.pickerText {
  @include toRem(font-size, 16px);
  color: $primary-text-color;
  margin-left: 22px;
}
