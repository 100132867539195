@import 'styles/variables-p44.scss';

.popover {
  cursor: pointer;
  display: inline-block;

  .p44-popover {
    @include toRem(font-size, 30px);
    color: $primary-grey-40;
    letter-spacing: -2px;
    line-height: 0;
  }
}

.primary {
  margin-right: 3px;
}

.identifiers {
  margin: 12px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.editIdentifiers {
  border-bottom: 1px solid $primary-grey-20;
  margin: 25px 0 10px;
  display: flex;
  flex-wrap: wrap;
}

.label {
  color: $primary-grey-80;
  font-size: 12px;
}

.addNewIdentifier {
  margin: 10px 0;

  button {
    justify-content: center;
    max-width: 90px;
  }
}

.container {
  color: $primary-grey;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 20px;

  .referenceValue {
    display: inline-block;
    max-width: 128px;

    input {
      font-weight: $font-weight-bold;
    }
  }
}

.editContainer {
  margin-bottom: 14px;
}

.value {
  font-weight: $font-weight-bold;
}

.oceanInsightsShipment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
}
