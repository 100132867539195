@import 'styles/variables-p44.scss';

.link {
  @include toRem(font-size, 12px);
  color: $primary-blue;
  display: inline-block;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
}

.disabledLink {
  color: $primary-grey-80;
  cursor: default;

  &:hover {
    color: $primary-grey-80;
  }
}

.downloadCSV i {
  line-height: 1;
  vertical-align: middle;
}
