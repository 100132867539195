@import 'styles/variables-p44.scss';

.settingsHeader {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding-left: 74px;
  padding-right: 74px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.settingsText {
  @include toRem(font-size, 28px);
  color: $primary-grey;
  font-weight: $font-weight-light;
}

.bolded {
  font-weight: $font-weight-semibold;
}

.buttons {
  align-items: center;
  display: flex;
}

.button {
  display: flex;
  justify-content: space-around;
  margin-right: 18px;
  min-width: 85px;

  &:last-child {
    margin-right: 0;
  }
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.show {
  opacity: 1;
  visibility: visible;
}
