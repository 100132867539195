@import 'styles/variables-p44.scss';

.navigation {
  text-align: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  ul {
    display: flex;
    list-style: none;
    margin-bottom: 0;
    margin-left: 8px;
    height: 64px;
    align-items: stretch;

    li {
      border-bottom: 5px solid transparent;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      a,
      .nav-item {
        @include toRem(font-size, 14px);
        color: $primary-grey-80;
        padding: 0 8px;
        text-decoration: none;

        @include media-breakpoint-down(md) {
          @include toRem(font-size, 12px);
        }

        &:hover {
          text-decoration: none;
          color: $primary-grey;
          font-weight: 500;
          background: rgba($black, 0.1);
          border-radius: 3px;
          padding: 6px 8px;
        }

        &__dropdown-icon {
          margin: 0 -8px 0 -4px;
        }
      }

      &.navigation-active {
        a,
        .nav-item {
          color: $primary-blue;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.navigationMenuDropdownOverlay {
  top: 45px !important;
  &.movement-banner-visible {
    top: 101px !important;
  }
}
