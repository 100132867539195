@import 'styles/variables-p44.scss';

.shipment-route {
  &__stop-count {
    @include toRem(font-size, 10px);
    color: $primary-grey;
    margin: 0 28px;
    text-align: center;
    text-transform: uppercase;

    .route-connect-arrow {
      align-items: center;
      color: $primary-blue;
      display: flex;

      hr {
        background-color: $primary-blue;
        height: 1px;
        margin-right: -1px;
        margin-top: 9px;
        width: 100%;
      }
    }
  }

  &__line {
    display: flex !important;
    flex-direction: column;
    height: 30px;

    .vertical-line-dotted {
      background: $primary-grey-35;
      display: flex;
      flex: 1;
      margin: auto auto 6px;
      position: relative;
      width: 2px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .shipment-route {
    &__toggle-route {
      @include toRem(font-size, 12px);
      font-weight: $font-weight-bold;
      margin-left: auto;
    }
  }
}
