.add-edit-shipment-actions {
  display: flex;
  margin-top: 30px;

  .spacer {
    flex: 1;
  }
  button {
    margin: auto;

    &:first-of-type {
      margin-left: 0;
      margin-right: auto;
      padding-left: 8px;
      padding-right: 8px;
      text-align: left !important;
    }

    &:last-of-type {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
