@import 'styles/variables-p44.scss';
@import '../LogoUpload.module.scss';

.dragAccept {
  background-color: $active-background-color;
}

.uploadWrapper {
  margin: 10px;
  opacity: 1;
  transition: .2s opacity;
}

.fadeAway {
  opacity: 0;
}

.hide {
  display: none;
}

.uploadErrorText {
  @include toRem(font-size, 14px);
  color: $error-color;
  padding: 10px;
  text-align: center;
}
