@import 'styles/variables-p44.scss';

.PageNotFound {
  margin: 150px auto 200px;
  padding-bottom: 3rem;
  padding-top: 3rem;
  text-align: center;
  width: 500px;

  .Shape {
    height: 60px;
    width: 60px;
  }

  .Oops-We-cant-find {
    @include toRem(font-size, 24px);
    color: $primary-grey-40;
    font-family: $font-family-base;
    font-stretch: normal;
    font-style: normal;
    font-weight: $font-weight-semibold;
    height: 64px;
    letter-spacing: normal;
    line-height: 1.33;
    width: 588px;
  }

  .Error-code-404 {
    @include toRem(font-size, 14px);
    color: $primary-grey-40;
    font-family: $font-family-base;
    font-stretch: normal;
    font-style: normal;
    font-weight: $font-weight-semibold;
    height: 32px;
    letter-spacing: normal;
    line-height: 2.29;
    margin-top: 50px;
    width: 96px;
  }
}
