@import 'styles/variables-p44.scss';

.add-edit-shipment-temp-form {
  .input-row {
    display: flex;

    > * {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      margin: 0 5px 8px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .shipment-temp-form {
    .temperature-control-row {
      align-items: center;
      display: flex;
      justify-content: center;

      .spinner-font-lower-bound {
        color: $primary-blue !important;
        font-weight: $font-weight-light !important;
      }

      .spinner-font-target-temp {
        color: $primary-grey !important;
        font-weight: $font-weight-semibold !important;
      }

      .spinner-font-upper-bound {
        color: $new-fruit !important;
        font-weight: $font-weight-light !important;
      }
    }

    .temperature-change-button-row {
      align-items: center;
      justify-content: center;

      .temperature-button {
        @include toRem(font-size, 12px);
        align-items: center;
        background-color: $primary-grey-05;
        border: none;
        box-shadow: 0 1px 2px 0 rgba($black, 0.5);
        color: $primary-grey-40;
        cursor: pointer;
        font-weight: $font-weight-normal;
        height: 40px;
        justify-content: center;
        margin: 0;
        max-width: 40px;
        outline: none;
        text-align: center;
        width: 40px;
      }

      .temperature-button-active {
        @include toRem(font-size, 12px);
        background-color: $bluish;
        border: none;
        color: $white;
        cursor: default;
        font-weight: $font-weight-bold;
        outline: none;
        text-align: center;
      }
    }
  }

  .the-section-text {
    margin-bottom: 50px;
  }

  .temperature-control {
    align-items: center;
    flex-grow: 0.1 !important;
    margin-right: 0 !important;

    .spinner {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      input[type='number'] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;
      }

      input {
        @include toRem(font-size, 24px);
        margin-bottom: 2px;
        outline: none;
        text-align: right;
        width: 60px;
      }
    }

    .spinner-right {
      align-content: center;
      display: flex;
      flex-grow: 0;

      .right-side-label {
        @include toRem(font-size, 24px);
        align-items: center;
        color: $orange;
        display: flex;
        font-stretch: normal;
        font-style: normal;
        font-weight: $font-weight-light;
        height: 100%;
        justify-content: center;
        letter-spacing: normal;
        padding-left: 8px;
        width: 35px;
      }
    }
  }
}
