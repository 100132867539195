@import 'styles/variables-p44.scss';

.headerContainer {
  background-color: $white;
  box-shadow: 0 2px 2px 0 rgba($black, 0.24);
  padding-left: 32px !important;
  padding-right: 32px !important;
  position: relative;

  @media only screen and (max-width: 870px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 20px;
  }

  .hamburgerMenuContainer img {
    cursor: pointer;
  }

  .headerRow {
    min-height: 64px;
  }

  .appLogoContainer {
    min-height: 64px;

    &:hover {
      cursor: pointer;
    }
  }

  .appLogo {
    height: 35px;
  }

  .publicLoginLinkSection {
    @include toRem(font-size, 12px);
    color: $primary-grey-80;

    .publicLoginLink {
      @include toRem(font-size, 13px);
      color: $bluish !important;
      font-weight: $font-weight-bold;
      width: 41px;
    }
  }

  .iconMarginRightSmall {
    margin-right: 10px;
  }

  .iconList {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding-left: 0;
    @include media-breakpoint-down(sm) {
      justify-content: flex-end;
    }

    li {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
