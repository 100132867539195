@import 'styles/modules/_p44Card.scss';

.card {
  @extend .p44Card;
}

.thermometerIcon {
  height: 75px;
  width: 75px;
  padding: 5px 0 15px 20px;
}

.currentTemp {
  @include toRem(font-size, 48px);
  display: flex;
  height: 65px;
}

.currentTempScale {
  @include toRem(font-size, 30px);
  padding-top: 8px;
}

.temperatureText {
  @include toRem(font-size, 14px);
}

.chartLabels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px 0 30px 15px;
  @include toRem(font-size, 14px);
}

.temperatureIcon {
  height: 30px;
  width: 30px;
}
