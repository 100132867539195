.checkbox-group {
  &.styled {
    .ant-checkbox-group {
      display: flex;
      flex-direction: row;
      border: solid 1px #d8d8d8;
      border-right: none;
      text-align: center;

      .ant-checkbox-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-right: solid 1px #d8d8d8;
        position: relative;
        margin: 0;

        .ant-checkbox-inner {
          display: none;
        }

        .ant-checkbox-checked {
          background-color: #e3ebf1;
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }

        .ant-checkbox + span {
          opacity: .25;
        }

        .ant-checkbox-checked + span {
          opacity: 1;
        }

        .item-icon {
          height: 15px;
          z-index: 1;
          position: relative;
        }

        .item-title {
          display: block;
          align-self: center;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.25;
          text-transform: uppercase;
          color: #575451;
          margin-top: 8px;
          z-index: 1;
          position: relative;
        }
      }
    }
  }
}
