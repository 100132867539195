@import 'styles/variables-p44.scss';

.shipment-details-ltl {
  h1 {
    margin-bottom: 10px;
  }

  .share-notifications-wrapper {
    display: flex !important;
    justify-content: flex-end;
  }
}
.no-documents-message {
  color: darken($primary-grey-50, 15%);
}

@include media-breakpoint-down(xs) {
  .shipment-details-ltl {
    .status {
      padding-left: 0;
      padding-right: 0;
    }

    .sticky-nav {
      display: none;
    }

    .mobile-sub-header {
      .title-container {
        top: -75px;
      }

      .identifier {
        &__label {
          color: $primary-grey;
          margin-bottom: 0;
        }
      }

      .custom-button {
        height: 40px;

        i {
          @include toRem(font-size, 24px);
        }
      }
    }
  }

  .ant-popover-inner-content {
    .carrier-detail-container {
      padding: 0;

      .CarrierDetail--ltl {
        align-items: flex-start;

        img {
          display: none;
        }

        h2 {
          @include toRem(font-size, 14px);
          font-weight: $font-weight-bold;
          color: $primary-grey-40;
          text-transform: none;
          text-align: left;
        }

        .carrier-info {
          margin-top: 0;

          .header-label {
            @include toRem(font-size, 12px);
            height: auto;
            width: auto;
          }

          .header-value {
            padding-left: 3px !important;
          }
        }
      }
    }
  }
}
