@import 'styles/variables-p44.scss';

.add-edit-shipment-form {
  .contact-info {
    margin: 15px 0;
  }

  .date-time-row {
    margin-bottom: 17px;

    .datepicker-group input {
      padding: 0 8px !important;
    }

    .date-time-separator {
      @include toRem(font-size, 12px);
      color: $primary-grey-20;
      margin: auto;
      padding: 10px;
      text-align: center;
    }
  }

  .toggle-contact-info {
    label {
      @include toRem(font-size, 10px);
      color: $teal;
      cursor: pointer;
      display: inline-block;
      padding-left: 20px;
      position: relative;
    }

    i {
      @include toRem(font-size, 17px);
      left: 0;
      position: absolute;
    }
  }
}

@media only screen and (max-width: 799px) {
  .add-edit-shipment {
    width: 100%;

    .input-row {
      flex-direction: column;

      > * {
        margin: 0 0 17px;
      }

      &.date-time-row {
        flex-flow: row wrap;

        > .input-container {
          width: 50%;
        }

        .date-time-separator {
          padding-right: 20px;
          text-align: center;
          width: 80%;
        }
      }
    }
  }
}
