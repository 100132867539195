@import 'styles/variables-p44.scss';

.route-stop-container {
  .route-stop {
    margin: 20px 0 24px;
    padding-left: 0;
    padding-right: 0;

    &__mode-icon {
      text-align: right;
      width: 120px;

      img {
        width: 72px;
      }
    }

    &__line {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 110px;

      img {
        margin-top: 5px;
        width: 33px;
      }

      .label {
        margin-top: 5px;
        text-transform: uppercase;
        @include toRem(font-size, 14px);
      }
    }

    &__stop-info {
      @include toRem(font-size, 12px);
      color: $primary-grey-80;

      .appointment-info {
        &__company {
          @include toRem(font-size, 16px);
          color: $primary-grey;
          font-weight: $font-weight-bold;
        }

        &__divider {
          @include toRem(font-size, 14px);
          color: $primary-grey-80;
          padding: 0 9px;
        }

        &__location {
          @include toRem(font-size, 14px);
          color: $primary-grey-80;
        }
      }

      .appointment-window {
        &__label {
          text-transform: uppercase;
          @include toRem(font-size, 14px);
        }
        &__range {
          @include toRem(font-size, 14px);
        }
      }
    }

    &__additional-info {
      align-self: flex-start;
      flex-grow: 1;
      padding-right: 20px;
      padding-top: 5px;
      text-align: right;

      .stop-tertiary-info {
        @include toRem(font-size, 14px);
      }

      .stop-contact-info {
        @include toRem(font-size, 14px);
        color: $primary-grey-80;

        &__divider {
          padding: 0 5px;
        }

        &__phone {
          color: $teal;
        }
      }
    }

    &__updates {
      padding-top: 10px;
    }
  }

  .empty-update {
    &__spacer {
      width: 120px;
    }

    &__line {
      display: flex;
      flex-direction: column;
      height: 30px;
      width: 110px;

      .vertical-line-dotted {
        background: $primary-grey-35;
        display: flex;
        flex: 1;
        margin: auto auto 6px;
        position: relative;
        width: 2px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .route-stop-container {
    .route-stop {
      &__mode-icon {
        display: none;
      }

      &__line {
        width: 45px;

        .label {
          display: none;
        }
      }

      &__additional-info {
        padding-left: 45px;
        text-align: left;
      }

      .appointment-info {
        display: flex;
        flex-direction: column;

        &__divider {
          display: none;
        }
      }
    }
  }
}
