@import 'styles/variables-p44.scss';

.subHeaderContainer {
  h1 {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: center;
  }
}

.listContainer {
  padding-bottom: 50px;
}

.title {
  margin-top: 25px;
  margin-bottom: 25px;

  h4 {
    margin-bottom: 0;
  }
  span {
    @include toRem(font-size, 14px);
  }
}
