@import 'styles/variables-p44.scss';

.errorBoundary {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}

.errorIcon {
  height: 80px;
  width: 80px;
}

.errorText {
  @include toRem(font-size, 18px);
}
