@import 'styles/variables-p44.scss';
.identifierContainer {
  line-height: 1.29;
  .identifier {
    font-weight: $font-weight-bold;
    cursor: pointer;
    color: $primary-blue;
  }
}
.linkButton {
  border-radius: 0 !important;
  border: transparent;
  background: border-box;
}
