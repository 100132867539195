@import 'styles/variables-p44.scss';

.notifications__blank-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: flex-start;
  padding-top: 80px;

  .blank-state__title {
    color: $primary-grey-40;
    margin-bottom: 15px;
  }

  .blank-state__icon {
    height: 72px;
    margin-bottom: 15px;
    opacity: .5;
    width: 72px;
  }

  .blank-state__text {
    color: $primary-grey-80;
  }
}
