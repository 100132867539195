@import 'styles/variables-p44.scss';

.notification-item {
  @include toRem(font-size, 12px);
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba($black, 0.5);
  color: $primary-grey-90;

  .title-row {
    align-items: center;
    min-height: 56px;

    h5 {
      color: inherit;
      font-size: inherit;
      font-weight: $font-weight-semibold;
      margin: 0;
    }
  }

  .collapse {
    transform: rotate(180deg);
  }

  .details {
    color: $primary-grey-80;
    font-weight: $font-weight-semibold;
  }

  .expanded {
    display: block !important;
  }

  .collapsed {
    display: none !important;
  }

  .edit-links {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: $teal;
      display: inline-block;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &:last-child {
        padding: 0;
      }

      a {
        color: $teal;
      }
    }
  }

  .link-disabled {
    pointer-events: none;
  }

  .cursor-disallowed {
    cursor: not-allowed;
  }
}

.notification-item:not(:first-child) {
  margin-top: 12px;
}

.notification-row {
  align-items: center;
  border-bottom: 1px solid $primary-grey-05;
  min-height: 42px;
  padding-bottom: 1rem;
  padding-top: 1rem;

  &:first-of-type {
    border-top: 1px solid $primary-grey-05;
  }

  &:last-of-type {
    border-bottom: none;
  }
}
