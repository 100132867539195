@import 'styles/variables-p44.scss';

.FleetListComponent {
  color: $black;
  padding: 0;
  position: relative;

  .filters {
    padding-right: 0;
  }

  .accordion {
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem 2rem;
  }

  .accordion-item {
    background-color: $white;
    cursor: pointer;
    margin: 0;
    width: 100%;
  }

  .accordion-items-container {
    height: 100%;
    max-height: 100%;
    width: 100%;

    .expandable-item-header {
      padding: 15px;
      position: relative;

      .expandable-item-section {
        @include toRem(font-size, 16px);
        display: flex;
        flex-direction: row;
        font-weight: $font-weight-bold;
      }
    }
  }

  .accordion-expander {
    margin-top: 15px;
    position: absolute;
    right: 10px;
  }

  .refine-button-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    @include media-breakpoint-down(xs) {
      text-align: left;
    }
  }

  .refine-button {
    box-shadow: 0 1px 2px 0 rgba($black, 0.5);
    color: $primary-grey-80;
    cursor: pointer;
    font-family: $font-family-base;
    height: 36px;
    width: 58px;
  }

  .add-fleet-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 100%;
  }

  .add-fleet-button {
    background-color: $teal !important;

    > button {
      border-radius: 50% !important;
    }
  }

  .number-of-search-results-container {
    @include toRem(font-size, 14px);
    color: $primary-grey;
    width: 100%;
  }

  .sort-filter-container {
    display: flex;
    width: 100%;
  }

  .sort-component-container {
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
  }

  .filters-sorting-container {
    margin-top: -12px;
    padding-bottom: 8px;
  }

  .filter-tunnel-button {
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    line-height: 1;
    margin: 0;
    outline: 0;
    padding: 0;

    &:active {
      outline: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    .filters {
      background-color: rgba($black, 0.5);
      bottom: 0;
      overflow: scroll;

      .FilterComponent {
        position: absolute;
        top: 0;
      }
    }

    .no-results-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 280px;
      justify-content: center;
      width: 100%;
    }

    .no-results-map {
      height: 72px;
      object-fit: contain;
      opacity: 0.5;
      width: 72px;
    }

    .no-results-text {
      @include toRem(font-size, 16px);
      color: $primary-grey-40;
      font-weight: $font-weight-bold;
      text-align: center;
      width: 100%;
    }

    .no-results-add-fleet-text {
      @include toRem(font-size, 14px);
      color: $primary-grey-80;
      line-height: 10px;
      padding-top: 8px;
      text-align: center;
      width: 100%;
    }

    .no-results-add-fleet-container {
      margin-top: 12px !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .FleetListComponent {
    .AppliedFilterList {
      text-transform: uppercase;
    }
  }
}

@include media-breakpoint-between(sm, md) {
  .FleetListComponent {
    .filters {
      background-color: rgba($black, 0.5);

      .FilterComponent {
        left: 50%;
        margin-left: -140px;
        position: absolute;
        top: 19px;
        width: 325px;
      }
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
}

.link {
  @include toRem(font-size, 12px);
  color: $primary-blue;
  display: inline-block;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  margin-left: 2rem;
}

.downloadCSV:hover {
  cursor: pointer
}
