@import '../Settings.module.scss';
@import 'styles/variables-p44.scss';

.inputHolder {
  height: 40px;
}

.tenantAttribute {
  padding: 8px 0;
  display: flex;
  font-size: 14px;
  .newAttribute {
    background-color: $green;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    text-align: right;
    color: $white;
    padding: 3px;
    margin-left: 5px;
    margin-top: 2px;
    align-self: center;
  }
}

.addRefDataKey {
  padding-bottom: 40px;
  padding-top: 20px;
  .refDataKey {
    input {
      height: 40px;
      max-width: 800px;
    }
  }
}
.duplicateText {
  color: $primary-blue;
  font-size: 14px;
}
