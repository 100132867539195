@import 'styles/variables-p44.scss';

.titleBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-top: 40px;
}

.title {
  @include toRem(font-size, 21px);
  color: $primary-text-color;
}

.previewToggle {
  border: 1px solid $light-border-color;
  display: flex;
}

.previewToggleItem {
  @include toRem(font-size, 12px);
  border-left: 1px solid $light-border-color;
  color: $primary-text-color;
  cursor: pointer;
  font-weight: $font-weight-bold;
  padding: 10px;

  &:first-child {
    border-left: none;
  }

  &.selected {
    background-color: $active-background-color;
  }
}

.previewBoxOuter {
  background-color: $dark-background-color;
  padding: 22px;
}

.helpText {
  @include toRem(font-size, 12px);
  color: $primary-text-color;
  margin-top: 8px;
}
