@import 'styles/variables-p44.scss';
@import './DocumentUploadComponent.module.scss';

.dragAccept {
  background-color: $active-background-color;
}

.dragReject {
  background-color: $active-background-color; 
}

.uploadWrapper {
  margin: 10px;
  opacity: 1;
  transition: .2s opacity;
  display: flex;
}

.fadeAway {
  opacity: 0;
}

.hide {
  display: none;
}

.browseButton {
  border: none;
  background-color: unset;
  color: $primary-blue;
  padding-left: 4px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.uploadErrorText {
  @include toRem(font-size, 14px);
  color: $error-color;
  padding: 10px;
  text-align: center;
}

.uploadError {
  border: 2px dashed $error-color;
}

.dropzone {
  &:hover {
    background-color: $active-background-color;
    cursor: pointer;
  }
}
