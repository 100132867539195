@import 'styles/variables-p44.scss';

.legend {
  background-color: rgba($chathams-blue, 0.5);
  color: $white;
  font-family: $font-family-base; // overrides mapbox's included font-family
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.legendInner {
  align-items: center;
  display: flex;
  height: 65px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  width: 1140px;
}

.legendIcons {
  @include toRem(font-size, 14px);
  align-items: center;
  display: flex;
  margin-left: 15px;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.title {
  @include toRem(font-size, 21px);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
