@import 'styles/variables-p44.scss';

.shipment-counts-container {
  padding: 0 0 57px;

  .react-resizable-handle {
    display: none;
  }

  .sub-header {
    background-color: transparent;

    .add-quick-view-wrapper {
      display: flex;

      .quick-view-title {
        .quick-view-greeting {
          @include toRem(font-size, 37px);
          color: $primary-grey;
          font-weight: $font-weight-light;
        }

        .quick-view-today-count {
          @include toRem(font-size, 21px);
          color: $primary-grey;
        }

        .quick-view-link {
          @include toRem(font-size, 14px);
          color: $primary-blue;
          font-weight: $font-weight-semibold;
          margin-left: 10px;
          min-height: 39px;
          padding: 10px 0;
          text-transform: uppercase;
        }
      }
    }
  }

  .react-grid-layout {
    padding: 0;
  }

  .view-all-widget {
    margin-top: 12px;
  }

  &.editing {
    .sub-header,
    .react-grid-layout {
      z-index: 2;
    }

    .react-resizable-handle {
      display: block;
      border-style: solid;
      border-width: 0 0 24px 37px;
      height: 0;
      width: 0;

      &:after {
        border: none;
        bottom: -15px;
        color: $secondary-background-color;
        content: 'RESIZE';
        font-weight: $font-weight-semibold;
        height: 17px;
        left: -75px;
        width: 78px;
      }
    }

    .shipment-counts-overlay {
      background-color: $secondary-background-color;
      bottom: 0;
      left: 0;
      opacity: 0.75;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  .shipment-counts-widget-container {
    > .row {
      margin-left: -6px;
      margin-right: -6px;
    }
  }

  .configure-view-link {
    color: $primary-blue;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }
}

@include media-breakpoint-down(xs) {
  // (max-width: 559px)
  .shipment-counts-container {
    padding-top: 0;
  }
}
