@import 'styles/variables-p44.scss';

.row-dragging {
  background: $primary-grey-98;
  border: 1px solid $chinese-silver;
  z-index: 9999;
  display: table;
  clear: both;
}

.row-dragging td {
  padding: 9px 15px 11px;
  visibility: hidden;
}

.row-dragging td:first-child {
    width: 14.2857142857%;
}

.row-dragging td:last-child {
  width: 10.5675146771%;
}

.row-dragging .drag-visible {
  visibility: visible;
}
