@import 'styles/variables-p44.scss';

$inner-margin: 55px;

.emailPreview {
  align-items: flex-start;
  background-color: $secondary-background-color;
  border-left: 20px solid;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
}

.logo {
  display: block;
  height: 35px;
  margin-bottom: 25px;
  margin-left: $inner-margin;
  margin-right: $inner-margin;
  object-fit: contain;
  width: 103px;
}

.emailHeader {
  @include toRem(font-size, 28px);
  margin: 0 $inner-margin;
}

.emailSubHeader {
  @include toRem(font-size, 20px);
  color: $secondary-text-color;
  font-weight: $font-weight-semibold;
  margin-bottom: 40px;
  margin-left: $inner-margin;
  margin-right: $inner-margin;
}

.divider {
  align-self: stretch;
  background: transparent;
  height: 30px;
  overflow: hidden;
  position: relative;

  &:before {
    border-radius: 400px / 10px;
    box-shadow: 0 0 30px 15px $primary-background-color;
    content: '';
    height: 20px;
    left: 2%;
    position: absolute;
    top: -20px;
    width: 96%;
    z-index: 1;
  }
}

.emailDetails {
  align-self: stretch;
  margin-bottom: 40px;
  margin-left: $inner-margin;
  margin-right: $inner-margin;
  margin-top: 20px;

  td {
    height: 35px;
    width: 50%;

    &:first-child {
      @include toRem(font-size, 12px);
      color: $secondary-text-color;
      text-transform: uppercase;
    }

    &:last-child {
      @include toRem(font-size, 16px);
      color: $primary-text-color;
    }
  }
}

.viewButton {
  align-self: stretch;
  justify-content: center;
  margin-left: $inner-margin;
  margin-right: $inner-margin;
  margin-top: 20px;
  transition: none !important;
}

.footer {
  color: $secondary-text-color;
  margin-left: $inner-margin;
  margin-right: $inner-margin;
  margin-top: 16px;
}
