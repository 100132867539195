@import 'styles/variables-p44.scss';

.item {
  margin: 0 6px;
}

.emissionsText {
  color: $dark-green;
  @include toRem(font-size, 14px);
}
