@import 'styles/variables-p44.scss';
@import 'styles/bootstrap/_bootstrap.scss';
.analytics-menu-container {
  z-index: $zindex-fixed;
  left: 0;
  position: fixed;
  transition-duration: 0.3s;
  width: 68px;

  &:hover {
    width: 262px;

    a {
      text-decoration: none;
    }
  }

  .analytics-menu {
    .analytics-menu-item {
      @include toRem(font-size, 14px);
      font-weight: $font-weight-bold;
      height: 60px;
      padding: 0 18px !important;
      text-transform: uppercase;

      span {
        @include toRem(font-size, 14px);
      }

      &.selected span {
        font-weight: $font-weight-bold;
      }
    }
  }
}
