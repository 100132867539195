.autocomplete {
  .ant-select {
    width: 100%;
  }
  &__input {
    margin-bottom: 16px;
    &:not(.ant-select-focused) {
      .ant-select-selection__placeholder {
        display: block !important;
      }
    }
    .ant-select-selection {
      border-radius: 0;
      &__choice {
        display: none;
      }
      &__choice {
        border-radius: 0;
      }
    }
  }
}
