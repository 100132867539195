/* blues */
$bluish: #00aeef;
$chathams-blue: #07456b;
$off-blue: #5888b0;
$p44-blue: #00558b;
$primary-blue: #00558b;
$primary-blue-13: #e3ebf1;
$primary-blue-30: #bdd0df;
$primary-blue-45: #9cb8ce;
$primary-blue-75: #5a89ae;
$primary-blue-85: #236192;
$primary-blue-95: #2e6998;

/* greys */
$grey: #080808;
$mysterious-grey: #aba9a8;
$primary-grey: #2d2926;
$primary-grey-05: #f3f3f3;
$primary-grey-20: #d5d4d4;
$primary-grey-30: #d8d8d8;
$primary-grey-32: #cccccc;
$primary-grey-35: #bbbbbb;
$primary-grey-40: #aba9a8;
$primary-grey-50: #979797;
$primary-grey-80: #575451;
$primary-grey-90: #4a4a4a;
$primary-grey-98: #fafafa;
$primary-grey-100: #e9e9e9;
$primary-grey-500: #aba9a8;

/* silvers */
$chinese-silver: #ccc;

/* blacks/whites */
$black: #000;
$white: #fff;
$white-two: #d5d4d4;

/* yellows */
$new-lemon: #f0c825;
$yellow: #ffcb05;
$highlight-yellow: rgba(255, 203, 5, 0.2);

/* oranges */
$michelangelo-orange: #ffab36;
$new-fruit: #f04e37;
$orange: #f7941d;

/* reds */
$error-red: #dc3545;
$new-error-red: #ca4236;

/* greens */
$dark-green: #00854a;
$green: #00a651;
$neon-green: #00ff00;
$teal: #1296b0;

/* element colors */
$polylineColor: $primary-blue;
$polygonShade: rgba(0, 174, 239, 0.5);

$primary-text-color: $primary-grey-80;
$secondary-text-color: $primary-grey-40;
$light-text-color: $white;

$primary-background-color: $primary-grey-05;
$secondary-background-color: $white;
$dark-background-color: $primary-grey-80;
$active-background-color: $primary-blue-13;
$active-text-color: $primary-blue-75;

$dark-border-color: $primary-grey-40;
$light-border-color: $primary-grey-20;

$error-color: $new-fruit;
$success-color: $green;
$link-color: $teal;

/* movement */
$movement-sidebar-background: #021c6b;
$movement-primary: #3142c8;
$movement-primary-hover: #2e39ad;
$movement-text-color: #091521;

$colorsList: (
  /* whites */ 'white' $white,
  'white-two' $white-two,
  /* blues */ 'bluish' $bluish,
  'chathams-blue' $chathams-blue,
  'off-blue' $off-blue,
  'p44-blue' $p44-blue,
  'primary-blue' $primary-blue,
  'primary-blue-13' $primary-blue-13,
  'primary-blue-30' $primary-blue-30,
  'primary-blue-45' $primary-blue-45,
  'primary-blue-75' $primary-blue-75,
  'primary-blue-95' $primary-blue-95,
  /* greys */ 'primary-grey' $primary-grey,
  'primary-grey-05' $primary-grey-05,
  'primary-grey-20' $primary-grey-20,
  'primary-grey-40' $primary-grey-40,
  'primary-grey-80' $primary-grey-80,
  'primary-grey-98' $primary-grey-98,
  'mysterious-grey' $mysterious-grey,
  /* silvers */
  'chinese-silver' $chinese-silver,
  /* yellows */ 'new-lemon' $new-lemon,
  'yellow' $yellow,
  /* oranges */ 'michelangelo-orange' $michelangelo-orange,
  'new-fruit' $new-fruit,
  'orange' $orange,
  /* greens */ 'green' $green,
  'dark-green' $dark-green,
  'teal' $teal
);
