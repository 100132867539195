@import 'styles/variables-p44.scss';

.route-update {
  flex-wrap: nowrap;
  min-height: 38px;

  &.has-description {
    min-height: 60px;

    .route-update__date,
    .route-update__info {
      padding-top: 10px;
    }

    .route-update__dotted-line {
      svg {
        top: 0;
      }
    }

    .route-update__line {
      .vertical-line {
        &:after {
          top: 20px;
        }
      }
    }
  }

  &__date {
    @include toRem(font-size, 14px);
    color: $primary-grey-80;
    flex-shrink: 0;
    text-align: right;
    text-transform: uppercase;
    width: 120px;
  }

  &__line {
    flex-shrink: 0;
    position: relative;
    width: 110px;

    svg {
      display: block;
      height: 50px;
      margin: auto;
      position: absolute;
      top: -15px;
      transform: translateX(50%);
      width: 50%;
      z-index: 1;
    }

    .vertical-line {
      background: $primary-grey-35;
      display: flex;
      flex: 1;
      height: 100%;
      margin: auto;
      position: relative;
      width: 2px;

      &:after {
        background-color: $primary-grey-35;
        border-radius: 50%;
        content: '';
        display: block;
        height: 8px;
        left: 50%;
        position: absolute;
        top: 10px;
        transform: translate(-50%, -50%);
        width: 8px;
      }
    }
  }

  &__dotted-line {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: flex-end;
    position: relative;
    width: 110px;

    svg {
      display: block;
      height: 50px;
      margin: auto;
      position: absolute;
      top: -15px;
      transform: translateX(50%);
      width: 50%;
      z-index: 1;

      .vertical-line-dotted {
        background: $primary-grey-35;
        display: flex;
        flex: 1;
        margin: 3px auto 5px;
        position: relative;
        width: 2px;
      }
    }

    &__label {
      @include toRem(font-size, 12px);
      color: $primary-grey-80;
      font-weight: $font-weight-bold;
      text-transform: uppercase;

      &.exception {
        color: $new-fruit;
      }
    }

    &__divider {
      @include toRem(font-size, 12px);
      color: $primary-grey-50;
      padding: 0 6px;
    }

    &__tertiary {
      @include toRem(font-size, 12px);
      color: $primary-grey-50;
      text-transform: uppercase;
    }

    &__description {
      @include toRem(font-size, 14px);
      margin: 3px 0 10px;
      padding-right: 20px;
    }

    &.has-description {
      &__date {
        align-items: center;
        display: flex;
        justify-content: flex-end;
      }

      &__info {
        align-items: center;
        display: flex;
      }
    }
  }

  &__divider {
    padding-right: 8px;
    padding-left: 8px;
  }
}

@include media-breakpoint-down(xs) {
  .route-update {
    &__date {
      flex: 1;
      order: 3;
      padding-right: 10px;
      width: auto;
    }

    &__line {
      order: 1;
      width: 50px;
    }

    &__hr {
      display: none;
    }

    &__info {
      display: block !important;
      flex-flow: nowrap !important;
      flex: 4 1;
      order: 2;
    }

    &__label,
    &__divider,
    &__tertiary {
      display: inline;
    }

    &__dotted-line {
      width: 50px;

      svg {
        width: 45px;
        transform: translateX(5%);
      }
    }
  }
}
