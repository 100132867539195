@import 'styles/variables-p44.scss';

.shipment-route {
  h2 {
    margin-bottom: 20px;
    font-weight: $font-weight-normal;
  }

  &__toggle-route {
    cursor: pointer;
    color: darken($teal, 20%);
    text-transform: uppercase;
    padding: 5px 20px;
  }
}
