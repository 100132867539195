@import 'styles/variables-p44.scss';

.tracking-info-text {
  @include toRem(font-size, 14px);

  .temperature-data {
    width: 80px;
  }

  .icon {
    height: 36px;
    object-fit: contain;
    width: 36px;
  }

  .under-min {
    color: $off-blue;
  }

  .over-max {
    color: $new-fruit;
  }

  span {
    color: $primary-grey;

    &.current-temp {
      @include toRem(font-size, 24px);
      font-weight: $font-weight-bold;
    }

    &.range-temp {
      @include toRem(font-size, 24px);
      font-weight: $font-weight-light;
    }

    &.temperature-label {
      @include toRem(font-size, 10px);
    }
  }
}
