@import 'styles/variables-p44.scss';

.add-edit-dialog {
  display: flex;
  width: 100%;

  .edit-shipment-title {
    padding: 25px;
    text-align: center;
  }

  .add-edit-shipment {
    margin-left: auto;
    margin-right: auto;
    width: 80%;

    .progress-wizard {
      display: flex;
      flex-direction: column;
      position: relative;

      .progress-wizard-content {
        margin-bottom: 27px;
        margin-top: 27px;

        .form-error {
          @include toRem(font-size, 12px);
          color: $new-fruit;
          font-weight: $font-weight-semibolder;
          padding-top: 15px;
        }
      }
    }
  }

  .progress-wizard-header {
    display: flex;
    flex-flow: column;
    flex: 1;
    margin: 0 auto 15px;
    min-height: 112px;
    width: 80%;

    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1;
      height: 32px;
    }

    &:after {
      clear: both;
      content: '';
    }

    h2 {
      margin-bottom: 25px;
      margin-top: 30px;
      text-align: center;
      width: 100%;
    }

    .progress-step {
      @include toRem(font-size, 10px);
      align-items: center;
      background-color: $primary-grey-05;
      box-shadow: 1px 1px 1px 0 rgba($black, 0.5);
      color: $primary-grey-40;
      display: flex;
      flex-grow: 1;
      font-weight: $font-weight-semibold;
      height: 32px;
      justify-content: center;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      .step-title {
        z-index: 4;
      }

      &:first-of-type {
        border-radius: 25px 0 0 25px;
        box-shadow: 0 1px 2px 0 rgba($black, 0.5);

        &:after {
          display: none;
        }
      }

      &:last-child {
        background-color: $primary-grey-05;
        border-radius: 0 25px 25px 0;
        box-shadow: 1px 1px 2px 0 rgba($black, 0.5);
        overflow: hidden;
      }

      &.completed {
        background-color: $chathams-blue;
        color: $white;
        cursor: pointer;
      }

      &.selected {
        background-color: $primary-blue;
        color: $white;
        margin-right: -1px;
        padding-left: 5px;
      }
    }
  }
}

.add-edit-shipment-container {
  .add-shipment-combo-button {
    padding-right: 64px;
    width: 263px;

    .combo-icon-wrapper {
      border-left: 1px solid $white;
      display: flex;
      flex-direction: column;
      height: 48px;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 48px;

      i {
        @include toRem(font-size, 24px);
      }
    }
  }

  .edit-shipment-icon {
    i.material-icons {
      @include toRem(font-size, 24px);
      color: $p44-blue;
    }
  }
}

.add-shipment-paper {
  border-radius: 0 !important;
  margin-top: 1px;
  padding: 10px 0;

  .add-shipment-menu {
    width: 263px;

    li {
      @include toRem(font-size, 14px);
      color: $primary-grey-80;
      height: 40px;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 560px) {
  .add-edit-content {
    width: 90% !important;
  }

  .progress-wizard-header {
    padding: 0 24px;
    width: auto;
  }
}

@media only screen and (max-width: 559px) {
  .add-edit-dialog {
    [role='document'] {
      margin: auto;
      min-width: auto !important;
      position: relative;

      .progress-wizard-header {
        margin: 0;
        padding: 0 16px;
        position: relative;
        width: auto;
      }

      .add-edit-paper > div {
        border: none !important;
        padding: 0 16px !important;
      }

      .DROPOFF_DETAIL .add-edit-shipment-actions {
        flex-flow: wrap;
      }

      .DROPOFF_DETAIL .add-edit-shipment-actions > button {
        flex-grow: 1;
      }

      .DROPOFF_DETAIL .add-edit-shipment-actions button:last-of-type {
        margin-top: 14px !important;
        width: 100% !important;
      }

      .progress-wizard-header h2 {
        @include toRem(font-size, 24px);
        margin-bottom: 12px;
      }

      .mobile-header {
        width: 100%;
      }

      .mobile-header h3 {
        @include toRem(font-size, 13px);
        color: $primary-blue;
        text-align: center;
        text-transform: uppercase;
      }

      .step-index {
        @include toRem(font-size, 11px);
        color: $bluish;
        margin-top: 5px;
        text-align: center;
      }

      .material-icons.cancel {
        color: $primary-blue;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
  }
}
