@import 'styles/variables-p44.scss';
@import 'styles/modules/_p44Card.scss';

.card {
  @extend .p44Card;
  color: $primary-grey;
  cursor: pointer;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  footer {
    padding-bottom: 0;
    padding-left: 18px;
  }

  &:hover {
    color: $primary-grey;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    background: rgba($primary-grey-05, 0.6);
  }

  p {
    margin: 0.2rem 0;
  }
}

.cardBody {
  padding: 0 15px 15px 15px;
}

.identifiersContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 0 38px;
}

.stopInfoContainer {
  padding-top: 15px;
}

.tag {
  margin-right: 10px;
}

.arrow {
  transform: rotate(180deg);
  margin: 0 15px;
}

.ellipses {
  margin-left: 15px;
}

.identifierSection {
  padding: 0;
  @include toRem(font-size, 16px);

  > *:nth-of-type(1) {
    margin: 0;
  }

  > * + * {
    margin: 0.5rem 0 0;
  }
}

.primaryIdentifier {
  font-weight: $font-weight-bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackingId {
  text-transform: uppercase;
}

.carrierName {
  @include toRem(font-size, 14px);
}

.lastUpdateText {
  @include toRem(font-size, 14px);
  font-style: italic;
  color: $primary-grey-80;
  margin-top: 10px !important;
}

.stopInfo {
  line-height: 1.8rem;
}

.statusActive {
  color: green;
}

.statusInActive {
  color: red;
}
.statusText {
  font-weight: $font-weight-bold;
}

.statusSeparator {
  display: flex;

  &:before {
    content: ' \25CF';
    @include toRem(font-size, 8px);
    margin: 0 5px;
    align-self: center;
  }
}

.modeIcon {
  height: 16px;
  width: 50px;
  margin-right: 10px;
  opacity: 0.8;
}

.activeHoldsLabel {
  @include toRem(font-size, 12px);
  font-weight: $font-weight-bold;
  padding-top: 1px;
  text-transform: uppercase;
}

.activeHoldsCountBubble {
  @include toRem(font-size, 10px);
  background-color: $orange;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  margin-left: 7px;
  border-radius: 10rem;
  display: inline-block;
  font-weight: $font-weight-bold;
  line-height: 1;
  padding: 0.25em 6px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.temperatureInfo {
  @include toRem(font-size, 12px);
  display: flex;
  justify-content: flex-end;
  color: $primary-grey-80;
  font-style: normal !important;
  font-weight: $font-weight-bolder !important;

  &.icon {
    height: 24px;
    object-fit: contain;
    width: 24px;
  }

  span {
    margin-top: 4px;

    &.under-min {
      color: $off-blue;
    }

    &.over-max {
      color: $new-fruit;
    }
  }
}

.statusSection {
  @include toRem(font-size, 14px);
  padding-bottom: 1.5rem;
  color: $primary-grey;
}

.shareBadge {
  background-color: rgba($primary-grey-05, 0.6);
  padding: 0.5rem 15px;
  width: 100%;
}

.temperatureIndicator {
  position: relative;

  img {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 2;
  }
}
.flexDirection {
  flex-direction: row-reverse;
}