@import 'styles/variables-p44.scss';

$mapheight: 550px;

.check-mark {
  content: '';
  display: inline-block;
  margin-left: 10px;

  // Make it a small rectangle so the border will create an L-shape
  height: 10px;
  width: 5px;

  // Add a border on the bottom and left, creating an 'L'
  border: solid $primary-grey;
  border-width: 0 2px 2px 0;

  // Rotate the L 45 degrees to turn it into a checkmark
  transform: rotate(45deg);
}

.map-detail {
  box-shadow: 0 1px 2px 0 rgba($black, 0.5);

  .map-detail__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
  }

  .map-detail__map-toggle {
    box-shadow: 0 1px 2px 0 rgba($black, 0.5);
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;

    .map-detail__toggle-btn {
      background-color: $primary-grey-05;
      border: 0;
      color: $primary-grey-80;
      padding: 10px;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
      }

      &--active {
        @extend .map-detail__toggle-btn;
        background-color: $bluish;
        color: $white;
      }
    }
  }

  .map-detail__map-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;

    .map-detail__side-panel {
      background-color: $white;
      border-right: 1px solid $white-two;
      display: flex;
      flex-direction: column;
      height: $mapheight;
      min-width: 320px;
      overflow: hidden;

      &--hidden {
        display: none;
      }

      .side-panel__header {
        border-bottom: 1px solid $white-two;
        padding: 12px 20px;

        h4 {
          margin-bottom: 0;
        }
      }

      .side-panel__item-wrapper {
        flex: 1;
        height: 100%;
        overflow: auto;
      }
    }

    .map-detail__map {
      background: $primary-grey-80;
      height: $mapheight;
      position: relative;
      width: 100%;
      z-index: 0;

      .current-stop-marker,
      .ping-marker {
        filter: drop-shadow(0 3px 5px rgba($black, 0.65));
      }

      .H_ib_body {
        background-color: $white;
        border-radius: 0;
        box-shadow: 0 1px 8px 0 rgba($black, 0.5);
        color: $primary-grey;
        font-family: $font-family-base;
        font-size: 1.25rem;
        padding: 16px;
        width: 190px;

        &::after {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $white;
          bottom: -5px;
          content: '';
          height: 0;
          position: absolute;
          right: 13px;
          width: 0;
        }

        .H_ib_close {
          svg {
            height: 20px;
            width: 20px;

            path {
              fill: $primary-grey;
            }
          }
        }

        .location-details {
          .stop-name {
            margin-bottom: 0;
          }

          .contact-details > p {
            margin-bottom: 0;
          }
        }

        .current-stop {
          > p {
            margin-bottom: 0;
          }

          .status-info {
            @include toRem(font-size, 16px);
            margin-bottom: 0;
          }

          .status-details {
            @include toRem(font-size, 14px);
            margin-bottom: 8px;

            span {
              font-weight: $font-weight-bold;
            }
          }

          .at-stop-message {
            color: $white;
            margin: 10px auto;
            padding: 2px 4px;
            text-align: center;
            text-transform: uppercase;
          }

          .next-stop-eta {
            margin-bottom: 8px;
            text-align: center;

            span {
              @include toRem(font-size, 16px);
              display: block;
              font-weight: $font-weight-bold;
            }
          }

          .status-update {
            @include toRem(font-size, 12px);
            color: $primary-grey-80;
            font-style: italic;
            text-align: center;
          }
        }
      }

      .H_ib_tail {
        svg {
          display: none;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  .H_ctl {
    &.H_zoom .H_btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:first-child {
        padding-top: 0.4em;
      }

      &:last-child {
        padding-bottom: 0.4em;
      }
    }

    > .H_btn {
      background-color: $primary-grey-05;
      border-radius: 0;
      box-shadow: 0 1px 2px 0 rgba($black, 0.5);

      svg.H_icon {
        fill: $primary-grey-80;
      }
    }
  }

  .H_overlay.H_open.H_bottom.H_right {
    background-color: $primary-grey-05;
    box-shadow: 0 1px 2px 0 rgba($black, 0.5);

    .H_rdo {
      border-bottom: 1px solid $white-two;

      .H_rdo_title {
        color: $primary-grey-80;
      }

      .H_rdo_buttons {
        > .H_btn {
          @include toRem(font-size, 12px);
          background-color: transparent;
          color: $teal;

          &.H_active::after {
            @extend .check-mark;
          }
        }
      }
    }

    .H_grp {
      border-bottom: 1px solid $white-two;

      .H_rdo_title {
        color: $primary-grey-80;
      }
      > .H_btn {
        @include toRem(font-size, 12px);
        background-color: transparent;
        color: $teal;

        &.H_active::after {
          @extend .check-mark;
        }
      }
    }

    > .H_btn {
      @include toRem(font-size, 12px);
      background-color: #ffffff;
      color: $teal;

      &.H_active::after {
        @extend .check-mark;
      }
    }

    &::after {
      border-color: transparent transparent transparent $primary-grey-05;
    }
  }

  .H_ib_close {
    background-color: #FFFFFF;
  }
}
